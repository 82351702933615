import Box from "@mui/material/Box";
import { Document, Page, pdfjs } from "react-pdf/dist/cjs/entry.webpack";
import * as React from "react";
import { useRef, useState } from "react";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import Paper from "@mui/material/Paper";
import SignaturePad from "react-signature-pad-wrapper";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { blueGrey } from "@mui/material/colors";
import Divider from "@mui/material/Divider";

const colors = {
  unaccepted: "lightskyblue",
  accepted: "green",
};

// Testing for prod fix...
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export default function PatientForm({ form, showComplete, signatures }) {
  const [file, setFile] = useState(form?.location);
  const [width, setWidth] = useState(300);
  const pdfRef = useRef(null);

  const [patientBgColor, setPatientBgColor] = useState(colors.unaccepted);
  const [patientCursor, setPatientCursor] = useState("auto");
  const [patientPointerEvents, setPatientPointerEvents] = useState("auto");
  const patientRef = useRef(null);

  const [serviceRepBgColor, setServiceRepBgColor] = useState(colors.unaccepted);
  const [serviceRepCursor, setServiceRepCursor] = useState("auto");
  const [serviceRepPointerEvents, setServiceRepPointerEvents] =
    useState("auto");
  const serviceRepRef = useRef(null);

  function setPdfWidth() {
    setWidth(pdfRef.current.offsetWidth);
  }

  function saveSignatures() {
    signatures({
      patient: patientRef.current.signaturePad.toDataURL(),
      serviceRep: serviceRepRef.current.signaturePad.toDataURL(),
    });
  }

  function acceptSig(type) {
    if (type === "patient") {
      setPatientBgColor(colors.accepted);
      setPatientCursor("not-allowed");
      setPatientPointerEvents("none");
      if (serviceRepBgColor === colors.accepted) {
        showComplete(true);
        saveSignatures();
      }
    } else if (type === "serviceRep") {
      setServiceRepBgColor(colors.accepted);
      setServiceRepCursor("not-allowed");
      setServiceRepPointerEvents("none");
      if (patientBgColor === colors.accepted) {
        showComplete(true);
        saveSignatures();
      }
    }
  }

  function clearSig(type) {
    showComplete(false);
    if (type === "patient") {
      patientRef.current.signaturePad.clear();
      setPatientBgColor(colors.unaccepted);
      setPatientCursor("auto");
      setPatientPointerEvents("auto");
    } else if (type === "serviceRep") {
      serviceRepRef.current.signaturePad.clear();
      setServiceRepBgColor(colors.unaccepted);
      setServiceRepCursor("auto");
      setServiceRepPointerEvents("auto");
    }
  }

  return (
    <Box ref={pdfRef}>
      {file && (
        <Box>
          <Document file={file} onLoadSuccess={setPdfWidth}>
            <Page
              pageNumber={1}
              renderTextLayer={false}
              renderAnnotationLayer={false}
              width={width}
            />
          </Document>

          <Divider sx={{ margin: "1em" }} />
        </Box>
      )}

      <Typography variant="h6">Patient Signature</Typography>
      <Card>
        <Paper
          sx={{
            backgroundColor: patientBgColor,
            cursor: patientCursor,
            pointerEvents: patientPointerEvents,
          }}
        >
          <SignaturePad ref={patientRef} redrawOnResize />
        </Paper>
        <Grid container>
          <Grid item xs={6} p={1}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: blueGrey[600],
                color: blueGrey[100],
                width: "100%",
              }}
              onClick={() => clearSig("patient")}
            >
              Clear
            </Button>
          </Grid>
          <Grid item xs={6} p={1}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: blueGrey[600],
                color: blueGrey[100],
                width: "100%",
              }}
              onClick={() => acceptSig("patient")}
            >
              Accept
            </Button>
          </Grid>
        </Grid>
      </Card>

      <Divider sx={{ margin: "1em" }} />

      <Typography variant="h6">Service Rep Signature</Typography>
      <Card>
        <Paper
          sx={{
            backgroundColor: serviceRepBgColor,
            cursor: serviceRepCursor,
            pointerEvents: serviceRepPointerEvents,
          }}
        >
          <SignaturePad ref={serviceRepRef} redrawOnResize />
        </Paper>
        <Grid container>
          <Grid item xs={6} p={1}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: blueGrey[600],
                color: blueGrey[100],
                width: "100%",
              }}
              onClick={() => clearSig("serviceRep")}
            >
              Clear
            </Button>
          </Grid>
          <Grid item xs={6} p={1}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: blueGrey[600],
                color: blueGrey[100],
                width: "100%",
              }}
              onClick={() => acceptSig("serviceRep")}
            >
              Accept
            </Button>
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
}
