// @mui material components
import Grid from "@mui/material/Grid";

// Settings page components
import BaseLayout from "../../layouts/BaseLayout/index.js";
import Header from "../../layouts/pages/account/settings/components/Header/index.js";
import Notifications from "../../components/Notifications.js";

const Profile = () => {
  return (
    <BaseLayout>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Header />
        </Grid>
        <Grid item xs={12}>
          <Notifications />
        </Grid>
      </Grid>
    </BaseLayout>
  );
};

export default Profile;
