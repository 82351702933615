const buildApiUrl = (path, params) => {
  const searchParams = new URLSearchParams(params);
  const pathJoined = ["", ...path].map((p) => encodeURIComponent(p)).join("/");
  const url = new URL(
    `${pathJoined}${params ? "?" : ""}${searchParams}`,
    process.env.REACT_APP_API_BASE
  );
  return url.toString();
};

const buildOpts = (method, body) => {
  const opts = {
    method,
    headers: {
      Accept: "application/json"
    },
  };

  if (body) {
    opts.body = JSON.stringify(body);
    opts.headers["Content-Type"] = "application/json";
  }

  return opts;
};

export const wrsCentralNoAuth = (token) => {
  const getTok = async (tok) => {
    const url = buildApiUrl(["central", "tokens", tok]);
    const opts = buildOpts("GET");
    const resp = await fetch(url, opts);
    const { status, data } = await resp.json();

    if ( status === 'ok' ) return data;

    if ( data ) return data;

    throw new Error('Invalid link');
  }

  const enrollNpiSam = async (npi, sigs) => {
    const url = buildApiUrl(["central", "tokens", token])

    const payload = {
      sigs: sigs,
      settings: {},
    }

    const opts = buildOpts("POST", payload);
    const resp = await fetch(url, opts);
    const { status, data } = await resp.json();

    if ( status === 'ok' ) return data;

    throw new Error('Unable to enroll in program');
  }

  const enrollNpiVt4 = async (npi, sigs, settings) => {
    const url = buildApiUrl(["central", "tokens", token])

    const payload = {
      sigs,
      settings: {
        lg: {
          temp: settings.largeTemperature,
          temp_change: settings.largeAllowTempChange,
          compress: settings.largeCompression,
          compress_change: settings.largeAllowCompressionChange,
          freq: settings.largeFrequency
        },
        sm: {
          temp: settings.smallTemperature,
          compress: settings.smallCompression,
          compress_change: settings.smallAllowCompressionChange,
          freq: settings.smallFrequency
        }
      }
    }

    const opts = buildOpts("POST", payload);
    const resp = await fetch(url, opts);
    const { status, data } = await resp.json();

    if ( status === 'ok' ) return data;

    throw new Error('Unable to enroll in program');
  }

  return {
    enrollNpiSam,
    enrollNpiVt4,
    getTok
  };
};