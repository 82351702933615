import * as React from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import BaseLayout from "../../layouts/BaseLayout";
import Timeline from "../../components/ServiceTimeline";
import { Fragment, useEffect, useState } from "react";
import ServiceCallToActions from "./ServiceCallToActions";
import {
  Alert,
  Card,
  CardActions,
  CardContent,
  CardHeader,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

function ServiceSingle() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [serviceData, setServiceData] = useState(["init", []]);
  const { api } = useOutletContext();
  const [status, apiData] = serviceData;

  const first_arrived_on = apiData[0]?.fit_on;
  const data =
    apiData.map((x) => ({ ...x, first_arrived_on })).slice(-1)[0] || {};

  useEffect(() => {
    if (status === "init") {
      api
        .getTreatment(id)
        .then((d) => {
          setServiceData(["loaded", d]);
        })
        .catch((error) => {
          setServiceData(["error", [error]]);
        });

      setServiceData(["loading", []]);
    }
  }, [serviceData]);

  if (status === "loaded") {
    return (
      <BaseLayout>
        <Typography variant="h3" align="center">
          {data.patient_name}
        </Typography>
        <Box mt={2}>
          <Alert
            variant="outlined"
            severity="info"
            icon={false}
            action={
              <IconButton
                size="small"
                onClick={() => navigate(`/patients/${data.eoc_id}`)}
              >
                <ArrowForwardIosIcon />
              </IconButton>
            }
          >
            Viewing a service item, for patient:
          </Alert>
        </Box>
        <ServiceCallToActions cycle={data} api={api} refresh={setServiceData} />
        {apiData.map((data) => {
          if (!data) return;
          return (
            <Fragment key={data.id}>
              <Box sx={{ width: "100%" }} mb={0} mt={2}>
                <Typography align="center" variant="h5">
                  Unit: {data.serial_num}
                </Typography>
              </Box>
              <Timeline cycle={data} />
            </Fragment>
          );
        })}
      </BaseLayout>
    );
  }

  return (
    <BaseLayout>
      {status === "error" ? <div>Error loading patient service</div> : <></>}
    </BaseLayout>
  );
}

export default ServiceSingle;
