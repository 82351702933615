import Enroll from './Enroll'
import {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import {blueGrey} from "@mui/material/colors";
import Typography from "@mui/material/Typography";
import * as React from "react";
import {useNavigate, useOutletContext} from "react-router-dom";
import {Alert, Snackbar} from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCopy} from "@fortawesome/pro-regular-svg-icons"
import {dateFmt} from "../formatters";

const blankRx = {
  cct: {
    location: '/cct-blank.pdf'
  },
  sam: {
    location: '/sam-blank.pdf'
  }
}

const settings = {
  cct: [
    {
      name: 'Temperature',
      type: 'select',
      values: [43, 44, 45],
    },
    {
      name: 'Extensions',
      type: 'checkbox',
    }
  ],
  sam: []
}

const methods = ['app', 'remote'];

const makeTokenUrl = (token) => {
  return [process.env.REACT_APP_ROOT_URL, 'sign', token].join('/');
}

async function makeEnrollmentUrl(api, npi, program) {
  const programMap = {
    'cct': 'enroll_vt4',
    'sam': 'enroll_sam'
  }

  const options = {
    purpose: programMap[program],
    entity_id: npi,
  }

  const res = await api.createTok(options);
  const { data } = res;
  console.log(data);

  // API CALL
  return makeTokenUrl(data);
}

const PickMethod = ({updater}) => {
  return (
    <Grid container>
      <Grid item xs={6} p={1}>
        <Button variant="contained" sx={{backgroundColor: blueGrey[600], color: blueGrey[100], width: "100%", height: 128}} onClick={()=>updater('app')}>
          In App<br />(NOW)
        </Button>
      </Grid>
      <Grid item xs={6} p={1}>
        <Button variant="contained" sx={{backgroundColor: blueGrey[600], color: blueGrey[100], width: "100%", height: 128}} onClick={()=>updater('remote')}>
          Remotely<br />(URL)
        </Button>
      </Grid>
    </Grid>
  )
}

export default function ProgramEnrollment({program, npi, onComplete}) {
  const { api } = useOutletContext();
  const [method, setMethod] = useState('');
  const [url, setUrl] = useState('');
  const [ccOpen, setCcOpen] = useState(false);

  useEffect(() => {
    async function getRemoteUrl() {
      const urlResponse = await makeEnrollmentUrl(api, npi, program);
      setUrl(urlResponse);
    }

    if (method === 'remote') {
      getRemoteUrl();
    }

  }, [method])

  const handleOpen = () => {
    setCcOpen(true);
  }

  const handleClose = () => {
    setCcOpen(false);
  }

  if (method === '') {
    return (
      <>
        <Grid item xs={12} alignContent="center">
          <Typography variant="h3" align="center" sx={{textTransform: "uppercase"}}>{program} Enrollment</Typography>
        </Grid>
        <PickMethod updater={setMethod}/>
      </>
    )
  }

  if (method === 'app') {
    console.log(`Program is: ${program}`)
    let enrollFunc = '';
    if (program === 'sam') {
      enrollFunc = api.enrollNpiSam;
    }
    if (program === 'cct') {
      enrollFunc = api.enrollNpiVt4;
    }

    if (enrollFunc === '') return <Box><Alert severity="error">Invalid enrollment link</Alert></Box>

    return <Enroll program={program} npi={npi} blankRx={blankRx[program]} onComplete={onComplete} api={enrollFunc} />
  }

  if (method === 'remote') {
    console.log(npi);
    console.log(url);

    const fields = [
      {
        name: 'NPI',
        value: npi,
      },
      {
        name: 'Program',
        value: program,
      },
      {
        name: 'URL',
        value: url,
      }
    ]

    function copyText(text) {
      navigator.clipboard.writeText(text)
        .then(() => {
          handleOpen();
        })
        .catch((err) => {

        })
      console.log("copied text: "+text);
    }

    const rows = fields.map((v) => {
      return (
       <>
         <Grid item xs={4}>
           <Box display="flex" justifyContent="flex-end">
             <Typography variant="body2" sx={{fontWeight: "400"}} width={"100%"}>{v.name}:</Typography>
           </Box>
         </Grid>
         <Grid item xs={8}>
           { (v.name === 'URL') ? <Button startIcon={<FontAwesomeIcon icon={faCopy} />} onClick={() => copyText(v.value)}>COPY URL</Button> : '' }
           { (v.name === 'Program') ? <Typography textTransform="uppercase" variant="body2">{v.value}</Typography> : '' }
           { (v.name === 'NPI') ? <Typography variant="body2">{v.value}</Typography> : '' }
         </Grid>
       </>
      )
    })

    return (
      <Box mt={1} p={1}>
        <Alert severity="success">A unique URL has been generated:</Alert>
        <Box mt={2}>
          <Card>
            <Box p={2}>
              <Grid container spacing={1}>
                {rows}
              </Grid>
            </Box>
          </Card>
          <Snackbar sx={{marginBottom: 12}} open={ccOpen} autoHideDuration={1500} onClose={handleClose} message="URL copied to clipboard..." />
        </Box>
      </Box>
    )
  }
}