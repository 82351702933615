import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {Dialog, Slide} from "@mui/material";
import * as React from "react";
import DelayFitting from "./DelayFitting";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import {useState} from "react";
import {blueGrey} from "@mui/material/colors";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogWrapper({open, closer, title, children}) {

  function handleClose() {
    console.log('handleClose called in:  ', title)
    closer()
  }

  return (
    <Dialog open={open} fullScreen onClose={() => closer()} TransitionComponent={Transition} >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => closer()}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" color={blueGrey[100]} component="div" sx={{ ml: 2, flex: 1 }}>
            {title}
          </Typography>
        </Toolbar>
      </AppBar>
      <Box>
        {children}
      </Box>
    </Dialog>
  )
}