import BaseLayout from "../../layouts/BaseLayout";
import Box from "@mui/material/Box";
import * as React from "react";
import {useNavigate, useParams} from "react-router-dom";
import ProgramEnrollment from "../../components/ProgramEnrollment";
import {Alert} from "@mui/material";

const programs = ['cct', 'sam']

export default function EnrollPage() {
  const {npi, program} = useParams();
  const navigate = useNavigate();

  if (!programs.includes(program)) {
    return (
      <BaseLayout>
        <Alert severity="warning">Not a valid program enrollment</Alert>
      </BaseLayout>
    );
  }

  return (
    <BaseLayout>
      <Box sx={{ width: '100%' }}>
        <ProgramEnrollment program={program} npi={npi} onComplete={() => navigate(`/prescribers/${npi}`)} />
      </Box>
    </BaseLayout>
  );
}