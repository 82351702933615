// @mui material components
import { Card, Grid, Box, Typography, Avatar } from "@mui/material";
import { blueGrey } from "@mui/material/colors";
import PersonIcon from "@mui/icons-material/Person";

function Header() {
  return (
    <Card id="profile">
      <Box p={2}>
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <Avatar sx={{ bgcolor: blueGrey[600] }}>
              <PersonIcon />
            </Avatar>
          </Grid>
          <Grid item>
            <Box height="100%" mt={0.5} lineHeight={1}>
              <Typography variant="h5" fontWeight="medium">
                Molly Sullivan
              </Typography>
              <Typography variant="button" color="text" fontWeight="medium">
                Agent Principal
                <br />
                Commolier HealthCare
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
}

export default Header;
