import { Document, Page, pdfjs } from "react-pdf"; ///dist/esm/entry.webpack'
import { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import SignaturePad from "react-signature-pad-wrapper";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { blueGrey } from "@mui/material/colors";
import * as React from "react";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import { useOutletContext } from "react-router-dom";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  InputLabel,
  Select,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";

// Testing for prod fix...
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const colors = {
  unaccepted: "lightskyblue",
  accepted: "green",
};

export default function Enroll({ program, blankRx, npi, onComplete, api }) {
  const [initBgColor, setInitBgColor] = useState(colors.unaccepted);
  const [initCursor, setInitCursor] = useState("auto");
  const [initPointerEvents, setInitPointerEvents] = useState("auto");
  const [extBgColor, setExtBgColor] = useState(colors.unaccepted);
  const [extCursor, setExtCursor] = useState("auto");
  const [extPointerEvents, setExtPointerEvents] = useState("auto");
  const [width, setWidth] = useState(300);
  const pdfRef = useRef(null);
  const initRef = useRef(null);
  const extRef = useRef(null);
  const [submission, setSubmission] = useState(["init", {}]);
  const [status, data] = submission;
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [settings, setSettings] = useState({
    largeTemperature: "",
    smallTemperature: "",
    largeCompression: "",
    smallCompression: "",
    largeFrequency: "",
    smallFrequency: "",
    largeAllowTempChange: "",
    largeAllowCompressionChange: "",
    smallAllowCompressionChange: "",
  });

  const file = blankRx.location;

  function myOnComplete() {
    const sigs = [
      initRef.current.signaturePad.toDataURL(),
      extRef.current.signaturePad.toDataURL(),
    ];

    if (program === "sam") {
      api(npi, sigs)
        .then((d) => {
          setSubmission(["success", d]);
        })
        .catch((error) => {
          setSubmission(["error", error]);
        });
    }

    if (program === "cct") {
      api(npi, sigs, settings)
        .then((d) => {
          setSubmission(["success", d]);
        })
        .catch((error) => {
          setSubmission(["error", error]);
        });
    }
  }

  useEffect(() => {
    if (status === "success") {
      onComplete();
    }
  }, [status]);

  useEffect(() => {
    const sigsGood =
      initBgColor === extBgColor && initBgColor === colors.accepted;
    const inputsGood = Object.values(settings).every((value) => value !== "");
    const sam = program === "sam";
    setSubmitDisabled(!(sigsGood && (inputsGood || sam)));
  }, [initBgColor, extBgColor, settings]);

  function acceptSig(type) {
    if (type === "init") {
      setInitBgColor(colors.accepted);
      setInitCursor("not-allowed");
      setInitPointerEvents("none");
    } else if (type === "ext") {
      setExtBgColor(colors.accepted);
      setExtCursor("not-allowed");
      setExtPointerEvents("none");
    }
  }

  function clearSig(type) {
    if (type === "init") {
      initRef.current.signaturePad.clear();
      setInitBgColor(colors.unaccepted);
      setInitCursor("auto");
      setInitPointerEvents("auto");
    } else if (type === "ext") {
      extRef.current.signaturePad.clear();
      setExtBgColor(colors.unaccepted);
      setExtCursor("auto");
      setExtPointerEvents("auto");
    }
  }

  function setPdfWidth() {
    setWidth(pdfRef.current.offsetWidth);
  }

  function Vt4SettingsInput() {
    const handleChange = (event) => {
      setSettings({
        ...settings,
        [event.target.name]: event.target.value,
      });
    };

    const config = {
      "Large Joint Settings": {
        largeTemperature: {
          fieldLabel: "Initial Temperature Setting",
          values: [43, 44, 45, 46, 47, 48, 49, 50],
          labels: [
            "43°F",
            "44°F",
            "45°F",
            "46°F",
            "47°F",
            "48°F",
            "49°F",
            "50°F",
          ],
        },
        largeAllowTempChange: {
          fieldLabel: "Allow Patient to Change Temperature",
          values: [true, false],
          labels: ["Yes", "No"],
        },
        largeCompression: {
          fieldLabel: "Initial Compression Setting",
          values: [15, 35, 50],
          labels: ["15 mmHg (Low)", "35 mmHg (Med)", "50 mmHg (High)"],
        },
        largeAllowCompressionChange: {
          fieldLabel: "Allow Patient to Change Compression",
          values: [true, false],
          labels: ["Yes", "No"],
        },
        largeFrequency: {
          fieldLabel: "Treatment Frequency",
          values: [15, 30, 45],
          labels: [
            "15 Minutes On / 45 Minutes Off",
            "30 Minutes On / 45 Minutes Off",
            "45 Minutes On / 45 Minutes Off",
          ],
        },
      },
      "Small Joint Settings": {
        smallTemperature: {
          fieldLabel: "Initial Temperature Setting",
          values: [49, 50],
          labels: ["49°F", "50°F"],
        },
        smallCompression: {
          fieldLabel: "Initial Compression Setting",
          values: [15, 35, 50],
          labels: ["15 mmHg (Low)", "35 mmHg (Med)", "50 mmHg (High)"],
        },
        smallAllowCompressionChange: {
          fieldLabel: "Allow Patient to Change Compression",
          values: [true, false],
          labels: ["Yes", "No"],
        },
        smallFrequency: {
          fieldLabel: "Treatment Frequency",
          values: [15, 30, 45],
          labels: [
            "15 Minutes On / 45 Minutes Off",
            "30 Minutes On / 45 Minutes Off",
            "45 Minutes On / 45 Minutes Off",
          ],
        },
      },
    };

    const settingSections = [];

    for (const [key, value] of Object.entries(config)) {
      const title = <Typography variant="h6">{key}</Typography>;

      const inputs = [];
      for (const [key2, value2] of Object.entries(value)) {
        const label = (
          <InputLabel key="label">
            <Typography variant="body2">{value2.fieldLabel}</Typography>
          </InputLabel>
        );

        const options = value2.values.map((val, i) => {
          return (
            <MenuItem value={val} key={i}>
              {value2.labels[i]}
            </MenuItem>
          );
        });

        const input = (
          <Select
            sx={{ width: "100%" }}
            value={settings[key2]}
            onChange={handleChange}
            name={key2}
          >
            {options}
          </Select>
        );

        inputs.push(
          <Box key={key2}>
            {label}
            {input}
          </Box>
        );
      }

      const section = (
        <Box key={key} my={2}>
          {title}
          {inputs}
        </Box>
      );
      settingSections.push(section);
    }

    return <Box>{settingSections}</Box>;
  }

  const SettingsInput = () => {
    if (program === "cct") return Vt4SettingsInput();
    return null;
  };

  return (
    <div>
      <Box ref={pdfRef}>
        <Document file={file} onLoadSuccess={setPdfWidth}>
          <Page
            pageNumber={1}
            renderTextLayer={false}
            renderAnnotationLayer={false}
            width={width}
          />
        </Document>
      </Box>

      <Divider />

      <SettingsInput />

      <Stack spacing={2}>
        <Box>
          <Typography variant="h6">Initial Rx Signature</Typography>
          <Card>
            <Paper
              sx={{
                backgroundColor: initBgColor,
                cursor: initCursor,
                pointerEvents: initPointerEvents,
              }}
            >
              <SignaturePad ref={initRef} redrawOnResize />
            </Paper>
            <Grid container>
              <Grid item xs={6} p={1}>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: blueGrey[600],
                    color: blueGrey[100],
                    width: "100%",
                  }}
                  onClick={() => clearSig("init")}
                >
                  Clear
                </Button>
              </Grid>
              <Grid item xs={6} p={1}>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: blueGrey[600],
                    color: blueGrey[100],
                    width: "100%",
                  }}
                  onClick={() => acceptSig("init")}
                >
                  Accept
                </Button>
              </Grid>
            </Grid>
          </Card>
        </Box>
        <Divider />
        <Box>
          <Typography variant="h6">Extension Rx Signature</Typography>
          <Card>
            <Paper
              sx={{
                backgroundColor: extBgColor,
                cursor: extCursor,
                pointerEvents: extPointerEvents,
              }}
            >
              <SignaturePad ref={extRef} redrawOnResize />
            </Paper>
            <Grid container>
              <Grid item xs={6} p={1}>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: blueGrey[600],
                    color: blueGrey[100],
                    width: "100%",
                  }}
                  onClick={() => clearSig("ext")}
                >
                  Clear
                </Button>
              </Grid>
              <Grid item xs={6} p={1}>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: blueGrey[600],
                    color: blueGrey[100],
                    width: "100%",
                  }}
                  onClick={() => acceptSig("ext")}
                >
                  Accept
                </Button>
              </Grid>
            </Grid>
          </Card>
        </Box>
        <Divider />
        <Box>
          <Button
            onClick={myOnComplete}
            variant="contained"
            sx={{
              backgroundColor: blueGrey[600],
              color: blueGrey[100],
              width: "100%",
            }}
            disabled={submitDisabled}
          >
            Finish
          </Button>
        </Box>
      </Stack>
    </div>
  );
}
