import { TextField } from "@mui/material";
import * as React from "react";
import dayjs from "dayjs";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import { useEffect, useState } from "react";

export default function EndTreatment({ closer, id, api }) {
  const [date, setDate] = useState(dayjs());
  const [reason, setReason] = useState(null);
  const [disabled, setDisabled] = useState(true);
  let top = "";

  const [apiData, setApiData] = useState(["init", {}]);
  const [status, data] = apiData;

  useEffect(() => {
    if (status === "submit") {
      console.log(date);
      const dateString = date.format("YYYY-MM-DD");
      api
        .endPatientTreatmentWithReason(id, dateString, reason)
        .then((d) => {
          setApiData(["loaded", d]);
        })
        .catch((error) => {
          setApiData(["error", error]);
        });

      setApiData(["submitting", {}]);
    }
    if (status === "submitting") {
      setDisabled(true);
    }
    if (status === "error") {
      checkDisabled(date, reason);
    }
    if (status === "loaded") {
      setTimeout(() => closer(), 100);
    }
  }, [apiData, status]);

  const checkDisabled = (dateIn, reasonIn) => {
    if (dateIn && reasonIn) setDisabled(false);
    else setDisabled(true);
  };

  const handleDateChange = (newValue) => {
    setDate(newValue);
    checkDisabled(newValue, reason);
  };

  const handleReasonChange = (event) => {
    checkDisabled(date, event.target.value);
    setReason(event.target.value);
  };

  function handleSubmit() {
    setApiData(["submit", {}]);
  }

  top = (
    <Stack spacing={2}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <MobileDatePicker
          label="End Treatment Date"
          inputFormat="MM/DD/YYYY"
          value={date}
          onChange={handleDateChange}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
      <TextField
        label="Reason"
        multiline
        fullWidth
        rows={4}
        onChange={handleReasonChange}
        placeholder="Reason for payor communication..."
      />
    </Stack>
  );

  return (
    <Box m={2}>
      {top}
      <Divider sx={{ margin: "1em" }} />
      <Stack spacing={2}>
        <Button
          variant="contained"
          autoFocus
          color="inherit"
          onClick={() => handleSubmit()}
          disabled={disabled}
        >
          Submit
        </Button>
      </Stack>
    </Box>
  );
}
