import {useEffect, useState} from "react";
import {CardHeader, CircularProgress} from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import * as React from "react";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

export default function NpiCardReview({data}) {
  const { taxonomies } = data;
  const classification = (taxonomies && taxonomies.length) ? taxonomies[0].classification : '';
  return (
    <Card>
      <CardHeader
        title={<Typography variant="h5">{data.name_first} {data.name_last}, {data.credential}</Typography>}
        subheader={<Typography variant="subtitle2">[{classification} - {data.practice_state}]</Typography>}
      />
      <Stack spacing={2} mb={3}>
        <Typography sx={{paddingLeft: 2, lineHeight: "0", color: "gray"}} variant="overline">Preferred Name:</Typography>
        <Typography sx={{paddingLeft: 2, lineHeight: "0", fontSize: "14px"}} variant="subtitle2">{data.provided.preferredName ? data.provided.preferredName : "[Not Entered]"}</Typography>
        <Divider />
        <Typography sx={{paddingLeft: 2, lineHeight: "0", color: "gray"}} variant="overline">Prescriber Email:</Typography>
        <Typography sx={{paddingLeft: 2, lineHeight: "0", fontSize: "14px"}} variant="subtitle2">{data.provided.prescriberEmail ? data.provided.prescriberEmail : "[Unknown]"}</Typography>
        <Divider />
        <Typography sx={{paddingLeft: 2, lineHeight: "0", color: "gray"}} variant="overline">Practice Name:</Typography>
        <Typography sx={{paddingLeft: 2, lineHeight: "0", fontSize: "14px"}} variant="subtitle2">{data.provided.practiceName ? data.provided.practiceName : "[Unknown]"}</Typography>
        <Divider />
        <Typography sx={{paddingLeft: 2, lineHeight: "0", color: "gray"}} variant="overline">Practice Address:</Typography>
        <Typography sx={{paddingLeft: 2, lineHeight: "0", fontSize: "14px"}} variant="subtitle2">{data.provided.practiceAddress ? data.provided.practiceAddress : "[Unknown]"}</Typography>
        <Divider />
        <Typography sx={{paddingLeft: 2, lineHeight: "0", color: "gray"}} variant="overline">Scheduler Name:</Typography>
        <Typography sx={{paddingLeft: 2, lineHeight: "0", fontSize: "14px"}} variant="subtitle2">{data.provided.schedulerName ? data.provided.schedulerName : "[Unknown]"}</Typography>
        <Divider />
        <Typography sx={{paddingLeft: 2, lineHeight: "0", color: "gray"}} variant="overline">Scheduler Phone:</Typography>
        <Typography sx={{paddingLeft: 2, lineHeight: "0", fontSize: "14px"}} variant="subtitle2">{data.provided.schedulerPhone ? data.provided.schedulerPhone : "[Unknown]"}</Typography>
        <Divider />
        <Typography sx={{paddingLeft: 2, lineHeight: "0", color: "gray"}} variant="overline">Scheduler Email:</Typography>
        <Typography sx={{paddingLeft: 2, lineHeight: "0", fontSize: "14px"}} variant="subtitle2">{data.provided.schedulerEmail ? data.provided.schedulerEmail : "[Unknown]"}</Typography>
      </Stack>
    </Card>
  )
}