import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import Link from "@mui/material/Link";
import { blueGrey } from "@mui/material/colors";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import { useState } from "react";
import MenuItem from "@mui/material/MenuItem";

export default function Header() {
  const navigate = useNavigate();
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const userMenuOpen = !!menuAnchorEl;

  const menuOpenClick = (e) => {
    setMenuAnchorEl(e.currentTarget);
  };
  const menuClose = () => setMenuAnchorEl(null);

  return (
    <>
      <Box sx={{ flexGrow: 1, backgroundColor: blueGrey[400] }}>
        <AppBar position="static">
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="white"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={() => navigate(-1)}
            >
              <ArrowBackIosNewIcon />
            </IconButton>
            <Typography
              variant="h6"
              color={blueGrey[100]}
              component="div"
              sx={{ flexGrow: 1 }}
            ></Typography>
            {/*<Link component="button" onClick={() => navigate(`/profile`)}>*/}
            <Link component="button" onClick={menuOpenClick}>
              {/*<Avatar src={molly} alt="profile-image" size="sm" shadow="sm" />*/}
              <Avatar sx={{ bgcolor: blueGrey[600] }}>
                <PersonIcon />
              </Avatar>
            </Link>
          </Toolbar>
        </AppBar>
      </Box>
      <Menu
        open={userMenuOpen}
        anchorEl={menuAnchorEl}
        onClose={menuClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          onClick={() => {
            navigate("/logout");
          }}
        >
          Logout
        </MenuItem>
      </Menu>
    </>
  );
}
