import BaseLayout from "../../layouts/BaseLayout";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import {CardHeader, InputBase} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import * as React from "react";
import {useNavigate, useOutletContext} from "react-router-dom";
import {useEffect, useState} from "react";
import Paper from "@mui/material/Paper";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import ColoredCard from "../../components/ColoredCard";

const invToStatus = {
  // "Pro Bono": 'warning',
  "Available": 'success',
  "With Patient": 'info',
  // "Needs to be Picked Up": 'error'
}

function Inventory() {
  const [search, setSearch] = useState('');
  const [[status, data], setInventoryData] = useState(['init', []]);
  const { api } = useOutletContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (status === 'init') {
      api.getInventory()
        .then((d) => {
          d.sort((a,b) =>
          {
            if (a.status === b.status) {
              return a.serial > b.serial;
            }
            return a.status > b.status;
          })
          setInventoryData(['loaded', d])
        })
        .catch((error) => {setInventoryData(['error', error])})
      setInventoryData(['loading', []])
    }
  }, [status, api])

  const inventory = data.filter((v) => {
    return (
      v.serial.toLowerCase().startsWith(search.toLowerCase())
      || v.serial.substring(1).toLowerCase().startsWith(search.toLowerCase())
      || v.status.toLowerCase().startsWith(search.toLowerCase())
      || search === ''
    )
  });

  const onChange = (event) => {
    console.log('here')
    setSearch(event.target.value);
  };

  const cards = inventory.map((rec) => {
    return (
        <ColoredCard key={rec.id} status={invToStatus[rec.status]}>
          <CardHeader
            title={<Typography variant="h5">{rec.serial}</Typography>}
            subheader={<Typography variant="subtitle2">{rec.status}</Typography>}
            action={
              <Stack direction="row" spacing={1}>
                <IconButton size="small" onClick={() => navigate(`/inventory/${rec.id}`)}>
                  <ArrowForwardIosIcon />
                </IconButton>
              </Stack>
            }
          />
        </ColoredCard>
    )
  })

  return (
    <BaseLayout>
      <Paper
        component="form"
        sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}
      >
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Filter Inventory"
          onChange={onChange}
          inputProps={{"type": "number", "pattern": "\\d*"}}
        />
        <IconButton sx={{ p: '10px' }} aria-label="search">
          <SearchIcon />
        </IconButton>
      </Paper>
      <Divider sx={{my: 1}}/>
      <Box sx={{ width: '100%' }}>
        <Stack spacing={1}>
          {cards}
        </Stack>
      </Box>
    </BaseLayout>
  )
}

export default Inventory;