const buildApiUrl = (path, params) => {
  const searchParams = new URLSearchParams(params);
  const pathJoined = ["", ...path].map((p) => encodeURIComponent(p)).join("/");
  const url = new URL(
    `${pathJoined}${params ? "?" : ""}${searchParams}`,
    process.env.REACT_APP_API_BASE
  );
  return url.toString();
};

const buildOpts = (method, token, body, impersonateId) => {
  const opts = {
    method,
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  if (impersonateId) {
    opts.headers["x-auth0-override"] = impersonateId;
  }

  if (body) {
    opts.body = JSON.stringify(body);
    opts.headers["Content-Type"] = "application/json";
  }

  return opts;
};

export const wrsCentral = (token, impersonateId) => {
  console.log("impersonateId got set to", impersonateId);
  const getToken = () => token;
  const getImpersonationId = () => impersonateId;

  const getMe = async () => {
    const url = buildApiUrl(["central", "me"]);
    const opts = buildOpts("GET", token, undefined, impersonateId);
    const resp = await fetch(url, opts);
    const { status, data, message } = await resp.json();

    if (status === "ok") return data;

    throw new Error(message);
  };

  const getPatient = async (id) => {
    const url = buildApiUrl(["central", "patients", id]);
    const opts = buildOpts("GET", token, undefined, impersonateId);
    const resp = await fetch(url, opts);
    const { status, data } = await resp.json();

    if (status === "ok") return data;

    throw new Error("Failed to get list of patients");
  };

  const getPatients = async () => {
    const url = buildApiUrl(["central", "patients"]);
    const opts = buildOpts("GET", token, undefined, impersonateId);
    const resp = await fetch(url, opts);
    const { status, data } = await resp.json();

    if (status === "ok") return data;

    throw new Error("Failed to get list of patients");
  };

  const getPrescribers = async () => {
    const url = buildApiUrl(["central", "prescribers"]);
    const opts = buildOpts("GET", token, undefined, impersonateId);
    const resp = await fetch(url, opts);
    const { status, data } = await resp.json();

    if (status === "ok") return data;

    throw new Error("Failed to get list of prescribers");
  };

  const getPrescriber = async (npi) => {
    const url = buildApiUrl(["central", "prescribers", npi]);
    const opts = buildOpts("GET", token, undefined, impersonateId);
    const resp = await fetch(url, opts);
    const { status, data } = await resp.json();

    if (status === "ok") return data;

    throw new Error(`Failed to get prescriber (${npi})`);
  };

  const addPrescriber = async (submission) => {
    const payload = {
      rep_id: 1,
      npi: submission.npi,
      name_preferred: submission.preferredName,
      email: submission.prescriberEmail,
      practice_name: submission.practiceName,
      practice_address: submission.practiceAddress,
      sched_name: submission.schedulerName,
      sched_phone: submission.schedulerPhone,
      sched_email: submission.schedulerEmail,
      notes: "",
    };

    const url = buildApiUrl(["central", "prescriber"]);
    const opts = buildOpts("POST", token, payload, impersonateId);
    const resp = await fetch(url, opts);
    const { status, data, message } = await resp.json();

    if (status === "ok") return data;

    if (status === "validation") throw new Error(message);

    throw new Error(`Failed to add new prescriber`);
  };

  const getInventory = async () => {
    const url = buildApiUrl(["central", "inventory"]);
    const opts = buildOpts("GET", token, undefined, impersonateId);
    const resp = await fetch(url, opts);
    const { status, data } = await resp.json();

    if (status === "ok") return data;

    throw new Error("Failed to get list of inventory");
  };

  const getInventoryId = async (id) => {
    const url = buildApiUrl(["central", "inventory", id]);
    const opts = buildOpts("GET", token, undefined, impersonateId);
    const resp = await fetch(url, opts);
    const { status, data } = await resp.json();

    if (status === "ok") return data;

    throw new Error("Failed to inventory");
  };

  const createTok = async (tokOpts) => {
    const url = buildApiUrl(["central", "tokens"]);
    const opts = buildOpts("POST", token, tokOpts, impersonateId);
    const resp = await fetch(url, opts);
    return resp.json();
  };

  const getTok = async (tok) => {
    const url = buildApiUrl(["central", "tokens", tok]);
    const opts = buildOpts("GET", token, undefined, impersonateId);
    const resp = await fetch(url, opts);
    const { status, data } = await resp.json();

    if (status === "ok") return data;

    if (data) return data;

    throw new Error("Invalid link");
  };

  const getService = async () => {
    const url = buildApiUrl(["central", "service"]);
    const opts = buildOpts("GET", token, undefined, impersonateId);
    const resp = await fetch(url, opts);
    const { status, data } = await resp.json();

    if (status === "ok") return data;

    throw new Error("Failed to get service list");
  };

  const getDashboard = async () => {
    const url = buildApiUrl(["central", "dashboard"]);
    const opts = buildOpts("GET", token, undefined, impersonateId);
    const resp = await fetch(url, opts);
    const { status, data } = await resp.json();

    if (status === "ok") return data;

    throw new Error("Unable to load dashboard");
  };

  const getTreatment = async (id) => {
    const url = buildApiUrl(["central", "treatment", id]);
    const opts = buildOpts("GET", token, undefined, impersonateId);
    const resp = await fetch(url, opts);
    const { status, data } = await resp.json();

    if (status === "ok") return data;

    throw new Error("Failed to lookup patient treatment");
  };

  const schedulePatientFittingWithReason = async (id, date, reason) => {
    const url = buildApiUrl(["central", "service", id, "schedule_fitting"]);

    const payload = {
      date: date,
      reason: reason,
    };

    const opts = buildOpts("POST", token, payload, impersonateId);
    const resp = await fetch(url, opts);
    const { status, data } = await resp.json();

    if (status === "ok") return data;

    throw new Error("Unable to schedule fitting.");
  };

  const endPatientTreatmentWithReason = async (id, date, reason) => {
    const url = buildApiUrl(["central", "service", id, "end"]);

    const payload = {
      service_end_on: date,
      reason: reason,
    };

    const opts = buildOpts("POST", token, payload, impersonateId);
    const resp = await fetch(url, opts);
    const { status, data } = await resp.json();

    if (status === "ok") return data;

    throw new Error("Unable to end treatment.");
  };

  const getNpiFromRegistry = async (npi) => {
    const url = buildApiUrl(["central", "npi", "registry", npi]);

    const opts = buildOpts("GET", token, undefined, impersonateId);
    const resp = await fetch(url, opts);
    const { status, data } = await resp.json();

    if (status === "ok") return data;

    throw new Error("Failed to reach NPI registry");
  };

  const schedulePatientPickup = async (id, date) => {
    const url = buildApiUrl(["central", "service", id, "schedule_pickup"]);

    const payload = {
      date: date,
    };

    const opts = buildOpts("POST", token, payload, impersonateId);
    const resp = await fetch(url, opts);
    const { status, data } = await resp.json();

    if (status === "ok") return data;

    throw new Error("Unable to schedule pickup.");
  };

  const fitPatient = async (
    id,
    sigs,
    fit_on,
    pdfData,
    inv_serial_id,
    inv_additional
  ) => {
    const url = buildApiUrl(["central", "treatment", id, "pic"]);

    const payload = {
      sigs: sigs,
      date: fit_on,
      pdfData: pdfData,
      inv_serial_id: inv_serial_id,
      wraps: inv_additional,
    };

    const opts = buildOpts("POST", token, payload, impersonateId);
    const resp = await fetch(url, opts);
    const { status, data, message } = await resp.json();

    if (status === "ok") return data;

    throw new Error(message);
  };

  const doCopa = async (treatId, sigs, picked_up_on, pdfData) => {
    const url = buildApiUrl(["central", "treatment", treatId, "copa"]);

    const payload = {
      sigs: sigs,
      picked_up_on: picked_up_on,
      pdfData: pdfData,
    };

    const opts = buildOpts("POST", token, payload, impersonateId);
    const resp = await fetch(url, opts);
    const { status, data, message } = await resp.json();

    if (status === "ok") return data;

    throw new Error(message);
  };

  const enrollNpiSam = async (npi, sigs) => {
    const url = buildApiUrl(["central", "enroll", npi, "sam2"]);

    const payload = {
      sigs: sigs,
      settings: {},
    };

    const opts = buildOpts("POST", token, payload, impersonateId);
    const resp = await fetch(url, opts);
    const { status, data } = await resp.json();

    if (status === "ok") return data;

    throw new Error("Unable to enroll in program");
  };

  const enrollNpiVt4 = async (npi, sigs, settings) => {
    const url = buildApiUrl(["central", "enroll", npi, "vt4"]);

    const payload = {
      sigs,
      settings: {
        lg: {
          temp: settings.largeTemperature,
          temp_change: settings.largeAllowTempChange,
          compress: settings.largeCompression,
          compress_change: settings.largeAllowCompressionChange,
          freq: settings.largeFrequency,
        },
        sm: {
          temp: settings.smallTemperature,
          compress: settings.smallCompression,
          compress_change: settings.smallAllowCompressionChange,
          freq: settings.smallFrequency,
        },
      },
    };

    const opts = buildOpts("POST", token, payload, impersonateId);
    const resp = await fetch(url, opts);
    const { status, data } = await resp.json();

    if (status === "ok") return data;

    throw new Error("Unable to enroll in program");
  };

  const ezReport = async (type) => {
    const url = buildApiUrl(["central", "ez-report"]);

    const payload = {
      report: type,
    };

    const opts = buildOpts("POST", token, payload, impersonateId);
    const resp = await fetch(url, opts);

    const { status, data } = await resp.json();

    if (status === "ok") return data;

    throw new Error("Unable to pull report");
  };

  const ezReportWeekly = async () => {
    const url = buildApiUrl(["central", "ez-report", "weekly"]);

    const opts = buildOpts("POST", token, undefined, impersonateId);
    const resp = await fetch(url, opts);

    const { status, data } = await resp.json();

    if (status === "ok") return data;

    throw new Error("Unable to pull report");
  };

  return {
    getToken,
    getImpersonationId,
    getMe,
    getService,
    getTreatment,
    getDashboard,
    getPatient,
    getPatients,
    getPrescribers,
    getPrescriber,
    addPrescriber,
    getInventory,
    getInventoryId,
    getNpiFromRegistry,
    enrollNpiSam,
    enrollNpiVt4,
    schedulePatientFittingWithReason,
    schedulePatientPickup,
    fitPatient,
    doCopa,
    endPatientTreatmentWithReason,
    createTok,
    getTok,
    ezReport,
    ezReportWeekly,
  };
};
