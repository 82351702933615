import Card from "@mui/material/Card";
import {CardContent, CardHeader} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faWavePulse} from "@fortawesome/pro-solid-svg-icons";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import Typography from "@mui/material/Typography";
import Timeline from "./ServiceTimeline";
import * as React from "react";
import styled from "@emotion/styled";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import {useNavigate} from "react-router-dom";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

function ServiceCard(props) {
  const navigate = useNavigate();
  const {cycle} = props;

  let subheader = '';
  if (cycle.inv_serial_id && cycle.inv_serial_num) {
    subheader = <Link style={{ cursor: 'pointer' }} underline="always" onClick={() => navigate(`/inventory/${cycle.inv_serial_id}`)}>{cycle.inv_serial_num}</Link>
  } else {
    subheader = ''
  }
  const title = 'Service'

  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card>
      <CardHeader
        avatar={<FontAwesomeIcon icon={faWavePulse} />}
        title={title}
        subheader={subheader}
        action={
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>}
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Timeline cycle={cycle} />
        </CardContent>
      </Collapse>
    </Card>
  )
}

export default ServiceCard;