import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import {Alert, Card, CardHeader, Skeleton} from "@mui/material";
import * as React from "react";
import ColoredCard from "./ColoredCard";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {useNavigate} from "react-router-dom";

const rowSkeletons = 10;

function PatientList({patients, dense = false}) {
  const navigate = useNavigate();

  let lastLetter = '';
  let section = '';

  let rows = []

  if (patients === null) {
    for (let index = 0; index < rowSkeletons; index++) {
      rows.push(<Skeleton variant="rectangular" height={100} key={index}/>)
    }
  } else {
    const list = patients.map((rec) => {
      const newLetter = rec.lastName.substr(0,1);
      if (lastLetter !== newLetter) {
        section = (<Box m={1}>{newLetter}</Box>)
        lastLetter = newLetter
      } else {
        section = '';
      }
      return (
        <Box key={rec.id} mt={0}>
          {!dense && section}
          <Card>
            <CardHeader
              title={<Typography variant="h5">{rec.lastName}, {rec.firstName}</Typography>}
              subheader={<Typography variant="subtitle2">{rec.city}, {rec.state}</Typography>}
              action={
                <Stack direction="row" spacing={1}>
                  <IconButton size="small" onClick={() => navigate(`/patients/${rec.id}`)}>
                    <ArrowForwardIosIcon/>
                  </IconButton>
                </Stack>
              }
            />
          </Card>
        </Box>
      )
    })
    rows.push([...list])
  }

  return (
    <Box sx={{ width: '100%' }} m={1}>
      <Stack spacing={1}>
        {patients.length > 0 && rows}
        {!patients.length &&
          <Alert severity="error">You have no patients to display here... time to make the rounds!</Alert>
        }
      </Stack>
    </Box>
  )
}

export default PatientList;