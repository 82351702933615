import {styled} from "@mui/material/styles";
import Card from "@mui/material/Card";
import {green} from "@mui/material/colors";

const statusColor = {
  'success': green[500],
  'info': "lightskyblue",
  'error': 'red',
  'warning': 'orange'
}

const ColoredCard = styled(Card, {shouldForwardProp: (prop) => prop !== "status"})(({ status }) => ({
  borderLeftColor: `${statusColor[status]}`,
  borderLeftWidth: "8px"
}));

export default ColoredCard;