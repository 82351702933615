import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { Box, ToggleButton, ToggleButtonGroup } from "@mui/material";
import BaseLayout from "../../layouts/BaseLayout/index.js";
import ServiceList from "../../components/ServiceList";

function Service() {
  const [tab, setTab] = useState("fitting");
  const [serviceData, setServiceData] = useState(["init", null]);
  const { api } = useOutletContext();
  const [status, data] = serviceData;

  useEffect(() => {
    if (status === "init") {
      api
        .getService()
        .then((d) => {
          setServiceData(["loaded", d]);
        })
        .catch((error) => {
          setServiceData(["error", error]);
        });

      setServiceData(["loading", null]);
    }
  }, [api, status]);

  const handleChange = (event, newTab) => {
    if (newTab === null) return;
    setTab(newTab);
  };

  const fittingCount = status === "loaded" ? data.fitting.length : "";
  const pickupCount = status === "loaded" ? data.pickup.length : "";

  return (
    <BaseLayout>
      <Box sx={{ width: "100%" }} mb={1}>
        <ToggleButtonGroup
          color="primary"
          value={tab}
          exclusive
          onChange={handleChange}
          fullWidth
          disabled={!["loaded", "error"].includes(status)}
        >
          <ToggleButton value="fitting">Fittings ({fittingCount})</ToggleButton>
          <ToggleButton value="pickup">Pickups ({pickupCount})</ToggleButton>
        </ToggleButtonGroup>
      </Box>
      {status === "error" ? (
        <div>Error Loading Service Data</div>
      ) : (
        <ServiceList data={data} tab={tab} />
      )}
    </BaseLayout>
  );
}

export default Service;
