// Setting pages components
import {
  Card,
  CardContent,
  CardHeader,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction, Switch
} from "@mui/material";
import {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as React from "react";
import {faMessageSms} from "@fortawesome/pro-regular-svg-icons/faMessageSms";
import {faEnvelope} from "@fortawesome/pro-regular-svg-icons/faEnvelope";
import List from "@mui/material/List";

function NotificationEntry(props){
  const { notification } = props;
  const [email, setEmail] = useState(notification.email);
  const [sms, setSms] = useState(notification.sms);

  return (
    <Card sx={{margin: "4px"}}>
      <CardHeader
        sx={{paddingBottom: "0px"}}
        title={notification.name}
        subheader={notification.description}
        subheaderTypographyProps={{variant: "caption"}}
      />
      <CardContent sx={{"&:last-child": {pb: "4px"}}}>
        <List>
          <ListItem>
            <ListItemButton onClick={() => setEmail(!email)} disableRipple>
              <ListItemIcon><FontAwesomeIcon icon={faEnvelope} size="lg" /></ListItemIcon>
              <ListItemSecondaryAction>
                <Switch checked={email} />
              </ListItemSecondaryAction>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton onClick={() => setSms(!sms)} disableRipple>
              <ListItemIcon><FontAwesomeIcon icon={faMessageSms} size="lg" /></ListItemIcon>
              <ListItemSecondaryAction>
                <Switch checked={sms} />
              </ListItemSecondaryAction>
            </ListItemButton>
          </ListItem>
        </List>
      </CardContent>
    </Card>
  )
}

export default NotificationEntry;
