import {
  Autocomplete,
  TextField,
  List,
  ListItem,
  Alert,
  CircularProgress,
} from "@mui/material";
import * as React from "react";
import Stack from "@mui/material/Stack";
import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import PatientForm from "../PatientForm";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {
  formatDateMM_DD_YYYY,
  formatDateYYYY_MM_DD,
  phoneFmt,
  treatSitesLabels,
} from "../../formatters";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import Link from "@mui/material/Link";

const Acknowledgements = ({ acks }) => {
  return (
    <Box mb={4}>
      {Object.entries(acks).map(([section, subSection]) => (
        <Box key={section}>
          <Typography variant="h6" mt={2} mb={1}>
            {section}
          </Typography>
          {Object.entries(subSection).map(([subTitle, paragraphs]) => (
            <Box key={subTitle}>
              <Typography variant="overline">{subTitle}</Typography>
              <List sx={{ listStyleType: "disc", pl: 4 }}>
                {paragraphs.map((paragraph, index) => (
                  <ListItem
                    sx={{ display: "list-item", py: 0, pl: 0 }}
                    key={index}
                  >
                    <Typography variant="caption">{paragraph}</Typography>
                  </ListItem>
                ))}
              </List>
            </Box>
          ))}
        </Box>
      ))}
    </Box>
  );
};

export default function FitPatient({ api, cycle, closer }) {
  const { treat_id } = cycle;

  const prevLabel = "Back";
  const doneLabel = "Submit";
  const forms = {
    selectUnit: "selectUnit",
    sign: "sign",
  };

  const [prevShow, setPrevShow] = useState(false);
  const [doneShow, setDoneShow] = useState(false);
  const [sigs, setSigs] = useState({});
  const [state, setState] = useState(forms.selectUnit);
  const [inventorySerialId, setInventorySerialId] = useState(0);

  const [apiData, setApiData] = useState(["init", {}]);
  const [status, data] = apiData;

  let top = "";

  const today = new Date();
  const fit_on_server = formatDateYYYY_MM_DD(today);
  const delivery_date = formatDateMM_DD_YYYY(today);

  useEffect(() => {
    if (status === "submit") {
      console.log("make api call");
      api
        .fitPatient(
          treat_id,
          sigs,
          fit_on_server,
          pdfData,
          inventorySerialId,
          selectedConsumables
        )
        .then((d) => {
          setApiData(["loaded", d]);
        })
        .catch((error) => {
          setApiData(["error", error]);
        });

      setApiData(["submitting", {}]);
    }
    if (status === "submitting") {
    }
    if (status === "error") {
    }
    if (status === "loaded") {
      setTimeout(() => closer(), 100);
    }
  });

  function handlePrev() {
    if (state === forms.sign) setState(forms.selectUnit);
  }

  function handleSubmit() {
    setApiData(["submit", {}]);
  }

  useEffect(() => {
    if (state === forms.selectUnit) {
      setPrevShow(false);
      setDoneShow(false);
    }
    if (state === forms.sign) {
      setPrevShow(true);
      setDoneShow(false);
    }
  }, [state]);

  const [selectedConsumables, setSelectedConsumables] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);

  const { settings } = cycle;

  if (!settings)
    return (
      <Box m={2}>
        <Alert severity="warning">
          Unable to fit this patient as there are no settings provided. Please
          contact WRS to resolve.
          <Link style={{ cursor: "pointer" }} href={`tel:7344925962`}>
            <Typography variant="body2">{phoneFmt("7344925962")}</Typography>
          </Link>
        </Alert>
      </Box>
    );

  const { sites, dvt, compression, timer, temp, duration, warnings } =
    cctSettingsInfo(cycle);

  function cctSettingsInfo(data) {
    const ranges = {
      15: "15 mmHg (Low)",
      35: "35 mmHg (Med)",
      50: "50 mmHg (High)",
    };

    const timers = {
      15: "15 Minutes On / 45 Minutes Off",
      30: "30 Minutes On / 45 Minutes Off",
      45: "45 Minutes On / 45 Minutes Off",
    };

    const sites = treatSitesLabels(data.sites).join(", ");
    const dvt = "";
    const compression = ranges[data.settings.compress];
    const timer = timers[data.settings.freq];
    const temp = `${data.settings.temp} Degrees`;
    const duration = `${data.rxLen} Days`;
    const warnings = "";

    return { sites, dvt, compression, timer, temp, duration, warnings };
  }

  const address = {
    addr_street1: cycle.patient_street1,
    addr_street2: cycle.patient_street2,
    addr_city: cycle.patient_city,
    addr_state: cycle.patient_state,
    addr_zip: cycle.patient_zip,
  };

  const pdfData = {
    patient_name: cycle.patient_name,
    address: address,
    dob: cycle.patient_dob,
    equipment: "VascuTherm 4",
    sites: sites,
    fit_on: fit_on_server,
    delivery_date: delivery_date,
    date: today,
  };

  const handleAddWrapButton = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseAddWrap = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (item) => {
    setSelectedConsumables((prevItems) => [
      ...prevItems,
      { ...item, uniqueKey: Math.random() },
    ]);
    handleCloseAddWrap();
  };

  const handleRemoveItemClick = (itemToRemove) => {
    setSelectedConsumables((prevItems) =>
      prevItems.filter((item) => item.uniqueKey !== itemToRemove.uniqueKey)
    );
  };

  const cctAcks = {
    "Patient Acknowledges & Understands use of VascuTherm4-M Equipment, Accessories & Documentation":
      {
        "Patient received the following items:": [
          "VascuTherm4-M (In-Home Use) unit & wrap(s)",
          "Thermoflow bottle",
          "5’ umbilical hose",
          "A/C power plug, external power supply & case",
          "VascuTherm4-M user’s manual",
          "VascuTherm4-M limited warranty & disclaimer",
        ],
      },
    "Patient Acknowledges & Understands VascuTherm4-M User Manual": {
      "Representative explained the following items:": [
        "Function and purpose of the system",
        "How to attach the A/C power plug to the system",
        "Importance of grounding all electrical equipment and of fire safety",
        "How to turn on & off the system",
        "How to use the system screen",
        "How to use the thermal & compression",
        "How to use the prescribed wrap applications",
        "How to prime the unit",
        "Proper placement of the system to allow for sufficient airflow",
        "How to clean and store the system, wraps and accessories",
        "How to insert and remove the coolant reservoir and drain the fluid in the system",
        "How to perform troubleshooting",
        "How to check for leaks in the system",
        "How to contact WRS if system malfunction",
        "The device may only be used indoors, not in or near a wet environment, in an ambient environment between 60-80 degrees Fahrenheit and at a relative humidity level below 60 percent",
        "What to do with the system when prescribed therapy is complete",
      ],
    },
    "Patient Acknowledges & Understands Prescribed Settings": {
      "The following are patient specific, physician-prescribed settings. Representative disclosed and explained to the patient:":
        [
          `Body Part(s) Treated: ${sites}`,
          `DVT Prophylactic Therapy: ${dvt}`,
          `Compression Level: ${compression}`,
          `Treatment “ON / OFF” time: ${timer}`,
          `Treatment temperature setting: ${temp}`,
          `Duration of need: ${duration}`,
          `Any prescriber precaution or warning: ${warnings}`,
        ],
      "Patient Acknowledges & Understands physician’s prescribed use of the VascuTherm4-M System.":
        [],
      "Patient Acknowledges opportunity to ask any & all questions that they’ve had regarding use of the VascuTherm4-M.":
        [],
    },
    "Patient Acknowledges & Understands Training on Safe & Appropriate Use of Equipment & Accessories":
      {
        "": [
          "The VascuTherm4-M is a prescribed therapy device, only the physician or medical practitioner can specify the actual therapy settings, area, frequency, and duration of treatment, and that the patient must follow these prescribed instructions",
          "The VascuTherm4-M requires programming the thermal on & off time to use the unit, patient is required to use the DME programming procedure as specified by the physician, and changes to the DME programming may require a new physician prescription",
          "Patient received information on the indications for use and contraindications associated with the VascuTherm4-M unit and applicable wraps & accessories",
          "Only the approved wraps and accessories may be used with the VascuTherm4-M",
          "There is potential for cold injury, even by providing cooling within the prescribed treatment settings",
          "If patient incurs any unusual swelling, skin discoloration or discomfort, immediately discontinue the use of the VascuTherm4-M and consult their healthcare professional",
          "Patient has a clear understanding of all warnings and precautions of the VascuTherm4-M",
          "Patient has been thoroughly trained and educated on the safe and appropriate use of the VascuTherm4-M",
        ],
      },
    "Patient Acknowledges & Understands use of Patient Data": {
      "": [
        "Patient understands that WRS uses patient data as provided in its Privacy Policy; a current version of which may be viewed on the WRS website at https://wrs.us/privacy-policy/. You may also request a paper copy by calling 734-929-2160 or writing to admin@wrspecialists.com.",
      ],
    },
  };
  const samAcks = {
    "Patient Acknowledges & Understands use of the SAM PRO 2.0, Accessories & Documentation":
      {
        "Patient received the following items:": [
          "SAM PRO 2.0",
          "SAM applicators",
          "Coupling patches",
          "Carrying case, charger, arm band",
        ],
      },
    "Patient Acknowledges & Understands SAM PRO 2.0 Directions for Use": {
      "Representative explained the following items:": [
        "Function and purpose of the system",
        "How to attach the power plug to the system",
        "How to turn on & off the system",
        "How to use the system button control",
        "How to use the timer",
        "How to use connect the applicators and patches",
        "Proper placement of patches",
        "How to add gel to body part",
        "How long to use SAM PRO 2.0",
        "How to charge SAM PRO 2.0",
        "How to perform troubleshooting",
        "How to contact WRS if SAM PRO 2.0 system is seemingly malfunctioning",
        "The device may only be used indoors, not in or near a wet environment, in an ambient environment between 60-80 degrees Fahrenheit and at a relative humidity level below 60 percent",
        "What to do with the system when prescribed therapy is complete",
      ],
    },
    "Patient Acknowledges & Understands Prescribed Usage": {
      "The following is physician prescribed. Representative disclosed and explained to the patient:":
        [
          "Body Part(s) Treated",
          "Length of treatment (4hrs max/day)",
          "Duration of need (number of days)",
          "Any prescriber precaution or warning",
        ],
      "Patient Acknowledges & Understands physician’s prescribed use of the SAM PRO 2.0 System.":
        [],
      "Patient Acknowledges opportunity to ask any & all questions that they’ve had regarding use of the SAM PRO 2.0":
        [],
    },
    "Patient Acknowledges & Understands Training on Safe & Appropriate Use of SAM PRO 2.0 & Accessories":
      {
        "": [
          "The SAM PRO 2.0 is a prescribed therapy device, only the physician or medical practitioner can specify the actual therapy settings, area, frequency, and duration of treatment, and that the patient must follow these prescribed instructions.",
          "Patient received information on the indications for use and contraindications associated with the SAM PRO 2.0 unit, applicators, patches, and accessories.",
          "Only the approved applicators and patches may be used with the SAM PRO 2.0.",
          "There is potential for skin irritation, even by providing ultrasound within the prescribed treatment settings.",
          "If patient incurs any unusual skin discoloration or discomfort, immediately discontinue the use of the SAM PRO 2.0 and consult their healthcare professional.",
          "Patient has a clear understanding of all warnings and precautions of the SAM PRO 2.0.",
          "Patient has been thoroughly trained and educated on the safe and appropriate use of the SAM PRO 2.0.",
        ],
      },
    "Patient Acknowledges & Understands use of Patient Data": {
      "": [
        "Patient understands that WRS uses patient data as provided in its Privacy Policy; a current version of which may be viewed on the WRS website at https://wrs.us/privacy-policy/. You may also request a paper copy by calling 734-929-2160 or writing to admin@wrspecialists.com.",
      ],
    },
  };

  const handleInventorySelect = (event, value) => {
    if (value) {
      setInventorySerialId(value.id);
    }
    setState(forms.sign);
  };

  if (status === "submitting") {
    return (
      <Box
        sx={{
          width: 100,
          height: 100,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  // return unit picker if init
  if (state === forms.selectUnit) {
    top = (
      <Stack spacing={2}>
        <Box>
          <Button variant="outlined" onClick={handleAddWrapButton}>
            Add a Wrap
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleCloseAddWrap}
          >
            {cycle.inventory.consumables.map((item) => (
              <MenuItem key={item.id} onClick={() => handleMenuItemClick(item)}>
                {item.label}
              </MenuItem>
            ))}
          </Menu>

          {selectedConsumables.map((item, index) => (
            <Box
              key={index}
              sx={{ display: "flex", alignItems: "center", gap: 2, mt: 2 }}
            >
              <IconButton onClick={() => handleRemoveItemClick(item)}>
                <RemoveCircleOutlineIcon />
              </IconButton>
              <Typography>{item.label}</Typography>
            </Box>
          ))}
        </Box>
        <Autocomplete
          disablePortal
          options={cycle.inventory.serials}
          fullWidth
          onChange={handleInventorySelect}
          renderInput={(params) => (
            <TextField {...params} label="Select Available Unit" />
          )}
        />
      </Stack>
    );
  }

  if (state === forms.sign)
    top = (
      <Box>
        <Stack spacing={1} my={2}>
          <Box>
            <Typography variant="overline">Patient Name:</Typography>
            <Typography variant="body2">{cycle.patient_name}</Typography>
          </Box>
          <Box>
            <Typography variant="overline">Address:</Typography>
            <Typography variant="body2">{cycle.patient_street1}</Typography>
            {cycle.patient_street2 && (
              <Typography variant="body2">{cycle.patient_street2}</Typography>
            )}
            <Typography variant="body2">
              {cycle.patient_city}, {cycle.patient_state}, {cycle.patient_zip}
            </Typography>
          </Box>
          <Box>
            <Typography variant="overline">Phone:</Typography>
            <Typography variant="body2">
              {phoneFmt(cycle.patient_phone)}
            </Typography>
          </Box>
          <Box>
            <Typography variant="overline">Date of Birth:</Typography>
            <Typography variant="body2">{cycle.patient_dob}</Typography>
          </Box>
          <Box>
            <Typography variant="overline">Wrap(s) Delivered:</Typography>
            <Typography variant="body2">
              {selectedConsumables.map((item) => item.label).join(", ")}
            </Typography>
          </Box>
        </Stack>
        <Divider sx={{ margin: "1em" }} />
        <Acknowledgements acks={cctAcks} />
        <Box mb={2}>
          <Typography variant="overline">
            Patient and Representative confirm that the above information is
            correct, and all training was received and completed.
          </Typography>
        </Box>
        {/*<PatientForm form={pics.cct} showComplete={setDoneShow} signatures={setSigs} />*/}
        <PatientForm showComplete={setDoneShow} signatures={setSigs} />
      </Box>
    );

  return (
    <Box m={2}>
      {top}
      <Divider sx={{ margin: "1em" }} />
      <Grid container>
        <Grid item xs={6} pr={1}>
          {prevShow ? (
            <Button
              variant="contained"
              autoFocus
              fullWidth
              color="inherit"
              onClick={() => handlePrev()}
            >
              {prevLabel}
            </Button>
          ) : (
            ""
          )}
        </Grid>
        <Grid item xs={6} pr={1}>
          {prevShow ? (
            <Button
              variant="contained"
              autoFocus
              disabled={!doneShow}
              fullWidth
              color="inherit"
              onClick={() => handleSubmit()}
            >
              {doneLabel}
            </Button>
          ) : (
            ""
          )}
        </Grid>
      </Grid>
    </Box>
  );
}
