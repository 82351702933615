import { useEffect, useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import BaseLayout from "../../layouts/BaseLayout/index.js";
import { phoneFmt, treatSitesLabels } from "../../formatters";
import ServiceCard from "../../components/ServiceCard";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  CardHeader,
  Grid,
  Link,
  Typography,
  Stack,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrescription } from "@fortawesome/pro-solid-svg-icons";
import DialogWrapper from "../../components/Actions/DialogWrapper";
import EndTreatment from "../../components/Actions/EndTreatment";

function formatDemoName(value) {
  const caps = ["dob", "doi", "dos"];
  const transform = caps.includes(value) ? "uppercase" : "capitalize";
  return (
    <Typography
      textTransform={transform}
      variant="body2"
      sx={{ fontWeight: "400" }}
      width={"100%"}
    >
      {value}:
    </Typography>
  );
}

function formatDemoValue(key, value) {
  if (key === "gender") {
    if (value === "M") value = "Male";
    else if (value === "F") value = "Female";
  } else if (key === "phone") {
    const href = `tel:${value}`;
    return (
      <Link style={{ cursor: "pointer" }} href={href}>
        <Typography variant="body2">{phoneFmt(value)}</Typography>
      </Link>
    );
  } else if (key === "address") {
    value = value.split("\n").map((line, i) => (
      <Typography key={i} variant="body2">
        {line}
      </Typography>
    ));
    return value;
  } else if (key === "sites") {
    value = treatSitesLabels(value).join(", ");
  }
  return <Typography variant="body2">{value}</Typography>;
}

function DemoEntry({ name, value }) {
  return (
    <>
      <Grid item xs={4}>
        <Box display="flex" justifyContent="flex-end">
          {formatDemoName(name)}
        </Box>
      </Grid>
      <Grid item xs={8}>
        {formatDemoValue(name, value)}
      </Grid>
    </>
  );
}

function Demographics({ demos }) {
  return (
    <>
      <Typography mt={2} variant="h6" align="center" textTransform="uppercase">
        Demographics
      </Typography>
      <Box mt={2}>
        <Card>
          <Box p={2}>
            <Grid container spacing={1}>
              <DemoEntry name="gender" value={demos.gender} />
              <DemoEntry name="dob" value={demos.dob} />
              <DemoEntry name="address" value={demos.address} />
              <DemoEntry name="phone" value={demos.phone} />
              <DemoEntry name="insurance" value={demos.ins_name} />
              {demos.adj_name && (
                <DemoEntry name="adjuster" value={demos.adj_name} />
              )}
              {demos.surgical && <DemoEntry name="dos" value={demos.dos} />}
              <DemoEntry name="doi" value={demos.doi} />
              <DemoEntry name="sites" value={demos.sites} />
              <DemoEntry name="diagnosis" value={demos.diagnosis} />
            </Grid>
          </Box>
        </Card>
      </Box>
    </>
  );
}

function Treatment({ treats }) {
  const navigate = useNavigate();
  const [endTreatmentOpen, setEndTreatmentOpen] = useState(false);
  const { api } = useOutletContext();
  const closeEndTreatment = () => {
    setEndTreatmentOpen(false);
  };
  return treats.map((treat) => {
    const scripts = treat.scripts.map((script) => {
      const type = script.therapy.toUpperCase();
      return (
        <Box key={`rx-${script.id}`}>
          <Card>
            <CardHeader
              avatar={<FontAwesomeIcon icon={faPrescription} />}
              title={`${type} — ${script.duration} days`}
              subheader={
                <Box>
                  <Link
                    style={{ cursor: "pointer" }}
                    underline="always"
                    onClick={() =>
                      navigate(`/prescribers/${script.prescriber_npi}`)
                    }
                  >
                    {script.prescriber_label}
                  </Link>
                  <div>Billing Status: {script.billing_status}</div>
                </Box>
              }
              // action={<Button size="large"><AttachmentIcon/> Rx</Button>}
            />
          </Card>
        </Box>
      );
    });
    const service = treat.service.map((sc) => {
      return (
        <Box mt={2} key={`sc-${sc.id}`}>
          <ServiceCard cycle={sc} />
        </Box>
      );
    });
    const endService = treat.service.map((sc) => {
      // if not fit, can't end service
      if (sc.fit_on === null) return;

      // if not agency channel, can't end service
      if (sc.service_channel !== "agency") return;

      // if service already has an end date, can't end service
      if (sc.service_end_on !== null) return;

      return (
        <Stack spacing={2} mb={2}>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={() => setEndTreatmentOpen(true)}
          >
            End Treatment
          </Button>
          <DialogWrapper
            open={endTreatmentOpen}
            closer={closeEndTreatment}
            title="End Patient Treatment"
          >
            <EndTreatment closer={closeEndTreatment} id={sc.id} api={api} />
          </DialogWrapper>
        </Stack>
      );
    });
    return (
      <Box
        mt={1}
        key={`treat-${treat.id}`}
        sx={{ p: 1, border: "1px dashed lightgray" }}
      >
        {endService}
        <Stack spacing={2}>{scripts}</Stack>
        <Stack spacing={2}>{service}</Stack>
      </Box>
    );
  });
}

function Patient() {
  let { id } = useParams();

  const [patientData, setPatientData] = useState(["init", {}]);
  const { api } = useOutletContext();
  const [status, data] = patientData;

  useEffect(() => {
    if (status === "init") {
      api
        .getPatient(id)
        .then((d) => {
          setPatientData(["loaded", d]);
        })
        .catch((error) => {
          setPatientData(["error", error]);
        });

      setPatientData(["loading", {}]);
    }
  }, [api, patientData, id, status]);

  if (status !== "loaded") return null;

  return (
    <BaseLayout>
      {!data?.treatments[0]?.fittable && (
        <Alert severity="error">
          <AlertTitle>Do Not Fit</AlertTitle>
          WRS does not yet have a script.
        </Alert>
      )}
      <Typography variant="h3" align="center">
        {data.demographics.name}
      </Typography>
      <Demographics demos={data.demographics} />
      <Typography
        mt={2}
        mb={1}
        variant="h6"
        align="center"
        textTransform="uppercase"
      >
        Treatments
      </Typography>
      <Treatment treats={data.treatments} />
    </BaseLayout>
  );
}

export default Patient;
