import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { wrsCentral } from "../api.js";
import LoadingAbortPaper from "../components/Reusable/LoadingAbortPaper";
import Typography from "@mui/material/Typography";
import InternalLink from "../components/Reusable/InternalLink";
import RepIdOverrideForm from "../components/RepIdOverrideForm";

const LoginGuard = () => {
  const { isAuthenticated, isLoading, error, getAccessTokenSilently } =
    useAuth0();
  const [loadError, setLoadError] = useState(null);
  const [api, setApi] = useState(null);
  const [token, setToken] = useState(null);
  const [me, setMe] = useState(null);
  const [impersonateId, setImpersonateId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      navigate("/");
      return;
    }

    if (!isAuthenticated || isLoading || error || loadError) return;

    if (!token) {
      getAccessTokenSilently()
        .then((t) => setToken(t))
        .catch((err) => setLoadError(err.message));
      return;
    }

    if (!api) {
      const newApiConfig = wrsCentral(token, impersonateId);
      setApi(newApiConfig);
      setMe(null);
      return;
    }

    if (
      api.getToken() !== token ||
      api.getImpersonationId() !== impersonateId
    ) {
      const newApiConfig = wrsCentral(token, impersonateId);
      setApi(newApiConfig);
      setMe(null);
      return;
    }

    if (!me) {
      api
        .getMe()
        .then((newMe) => {
          setMe(newMe);
        })
        .catch((err) => setLoadError(err.message));
      return;
    }
  }, [
    isAuthenticated,
    isLoading,
    error,
    loadError,
    getAccessTokenSilently,
    api,
    impersonateId,
    token,
    me,
    navigate,
  ]);

  if (isLoading) {
    return <div>Auth0 Loading...</div>;
  }

  if (error) {
    return <div>Auth0 Error: {error.message}</div>;
  }

  if (loadError) {
    return <div>Loading Error: {loadError}</div>;
  }

  if (!api || !me) {
    return <div>WRS Central is Loading...</div>;
  }

  if (me.error) {
    return (
      <LoadingAbortPaper>
        <Typography>{me.error}</Typography>
      </LoadingAbortPaper>
    );
  }

  if (!me.permissions.includes("central:login")) {
    return <div>User is not allowed into WRS Central</div>;
  }

  if (!me.rep_id && me.permissions.includes("central:impersonate")) {
    return (
      <RepIdOverrideForm
        onImpersonateCommit={(repId) => setImpersonateId(repId)}
      />
    );
  }

  if (!me.rep_id) {
    return (
      <LoadingAbortPaper>
        <Typography>
          This user has not yet been setup for WRS Central access
        </Typography>
        <InternalLink to="/logout">Logout and Start Over</InternalLink>
      </LoadingAbortPaper>
    );
  }

  return (
    <>
      <Outlet context={{ api: api, me: me }} />
    </>
  );
};

export default LoginGuard;
