import {useEffect, useState} from "react";
import {Alert, CircularProgress} from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import * as React from "react";
import {useOutletContext} from "react-router-dom";

const states = {
  init: 'init',
  loading: 'loading',
  loaded: 'loaded',
  error: 'error',
}

export default function NpiCard({npi, setNpiFound}) {
  const [serviceData, setServiceData] = useState(['init', null])
  const { api } = useOutletContext();
  const [status, data] = serviceData;

  useEffect(() => {
    if (status === states.init) {
      api.getNpiFromRegistry(npi)
        .then((d) => {setServiceData(['loaded', d])})
        .catch((error) => {
          setServiceData(['error', error]);
          setNpiFound({});
        })

      setServiceData(['loading', null])
    }
    if (status === states.loaded) {
      setNpiFound(data);
    }
  }, [status])

  if (status === states.init) {
    return (
      <CircularProgress />
    )
  }

  if (status === states.loading) {
    return (
      <CircularProgress />
    )
  }

  if (status === states.error) {
    console.log(data);
    return (
      <Alert severity="warning">Unable to find NPI</Alert>
    )
  }

  if (status === states.loaded) {
    const { taxonomies } = data;
    const classification = (taxonomies && taxonomies.length) ? taxonomies[0].classification : '';
    return (
      <Box>
        <Typography variant="h5">{data.name_first} {data.name_last}, {data.credential}</Typography>
        <Typography variant="body2">[{classification} - {data.practice_state}]</Typography>
      </Box>
    )
  }
}