// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Setting pages components
import * as React from "react";
import NotificationEntry from "./NotificationEntry";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Input} from "@mui/material";

const notifications = [
  {
    id: 1,
    name: "Incomplete",
    description: "Triggers when an intake form is marked as incomplete.",
    email: true,
    sms: false,
  },
  {
    id: 2,
    name: "Accepted",
    description: "Triggers when WRS accepts a new patient.",
    email: true,
    sms: false,
  },
  {
    id: 3,
    name: "New Extension",
    description: "Triggers when WRS generates an extension.",
    email: true,
    sms: false,
  },
  {
    id: 4,
    name: "Pre-Authorization Result",
    description: "Triggers when WRS gets a pre-authorization answer.",
    email: true,
    sms: false,
  },
  // {
  //   id: 5,
  //   name: "PIC",
  //   description: "Triggers when a new PIC is posted.",
  //   email: true,
  //   sms: false,
  // },
  // {
  //   id: 6,
  //   name: "COPA",
  //   description: "Triggers when a new COPA is posted.",
  //   email: false,
  //   sms: false,
  // },
  // {
  //   id: 7,
  //   name: "Charge Out",
  //   description: "Triggers when WRS generates an invoice to a payor.",
  //   email: false,
  //   sms: false,
  // },
  {
    id: 8,
    name: "Commission",
    description: "Triggers when a commission posts to agency account.",
    email: false,
    sms: true,
  },
];

function Notifications() {
  const notificationEntry = (notification) => {
    return <NotificationEntry notification={notification} key={notification.id}/>
  }

  return (
    <Card id="notifications">
      <Box p={3} pb={0} lineHeight={1}>
        <Box mb={1}>
          <Typography variant="h5">Notifications</Typography>
        </Box>
        <Typography variant="button" color="text">
          Choose how and when you receive notifications from WRS regarding patients.
        </Typography>
      </Box>
      <Box p={3} pb={0}>
        <Grid item xs={12} sm={6}>
          <Input
            label="Email"
            placeholder="molly@commolier.com"
            inputProps={{ type: "email" }}
          />
        </Grid>
      </Box>
      <Box p={3}>
        <Grid item xs={12} sm={6}>
          <Input
            label="SMS"
            placeholder="(248) 555-1212"
            inputProps={{ type: "phone" }}
          />
        </Grid>
      </Box>
      {notifications.map(notification => notificationEntry(notification))}
    </Card>
  );
}

export default Notifications;
