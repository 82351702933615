import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  Alert,
  Avatar,
  Button,
  Card,
  Box,
  CardHeader,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import BaseLayout from "../../layouts/BaseLayout/index.js";
import { blueGrey } from "@mui/material/colors";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import HomeIcon from "@mui/icons-material/Home";
import ColoredCard from "../../components/ColoredCard";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import DialogWrapper from "../../components/Actions/DialogWrapper";
import ReassignRep from "../../components/Actions/ReassignRep";
import { phoneFmt } from "../../formatters";

function PrescriberCard({ title, count, diff, navigate = "" }) {
  const nav = useNavigate();

  const color = diff > 0 ? "success" : diff < 0 ? "error" : "warning";

  const action = navigate && (
    <Stack direction="row" spacing={1}>
      <IconButton size="small" onClick={() => nav(navigate)}>
        <ArrowForwardIosIcon />
      </IconButton>
    </Stack>
  );

  return (
    <ColoredCard status={color} xs={12}>
      <CardHeader
        title={
          <Typography variant="h5">
            {count} {title}
          </Typography>
        }
        action={action ? action : ""}
      />
      <Box pl={2} pb={1}></Box>
    </ColoredCard>
  );
}

function Prescriber() {
  let { npi } = useParams();
  const navigate = useNavigate();

  const [prescriberData, setPrescriberData] = useState(["init", {}]);
  const { api, me } = useOutletContext();
  const [status, data] = prescriberData;

  useEffect(() => {
    if (status === "init") {
      api
        .getPrescriber(npi)
        .then((d) => {
          setPrescriberData(["loaded", d]);
        })
        .catch((error) => {
          setPrescriberData(["error", error]);
        });

      setPrescriberData(["loading", {}]);
    }
  }, [prescriberData]);

  const [reassignRepOpen, setReassignRepOpen] = useState(false);
  const closeReassignRep = () => setReassignRepOpen(false);

  if (status !== "loaded")
    return (
      <BaseLayout>
        {status === "error" && (
          <Alert severity="warning">Unable to load selected prescriber</Alert>
        )}
      </BaseLayout>
    );

  const sam = data?.sigs.includes("sam");
  const cct = data?.sigs.includes("cct");

  const reassign =
    me?.roles.includes("agent") || me?.roles.includes("principal");

  const { address } = data.practice;
  const { contact } = data;

  return (
    <BaseLayout>
      <Typography variant="h3" align="center">
        {[data.firstName, data.lastName, data.credential].join(" ")}
      </Typography>
      <Typography variant="h6" align="center">
        {data.practice.name}
      </Typography>
      <Box display="flex" justifyContent="center" alignItems="center">
        <Typography variant="caption" align="center">
          Sales Rep: {data.repName}
        </Typography>
      </Box>
      {!cct && (
        <Grid mt={1} mb={1}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => navigate(`/prescribers/${npi}/enroll/cct`)}
            fullWidth
            p={4}
          >
            Enroll in CCT
          </Button>
        </Grid>
      )}
      {!sam && (
        <Grid mt={1} mb={1}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => navigate(`/prescribers/${npi}/enroll/sam`)}
            fullWidth
            p={4}
          >
            Enroll in SAM
          </Button>
        </Grid>
      )}
      {false && reassign && (
        <Grid mt={1} mb={1}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            p={4}
            onClick={() => setReassignRepOpen(true)}
          >
            Reassign Rep
          </Button>
        </Grid>
      )}
      {address && address.hasOwnProperty("addr_street1") && (
        <Grid mt={2} mb={1}>
          <Card>
            <CardHeader
              avatar={
                <Avatar sx={{ bgcolor: blueGrey[400] }}>
                  <HomeIcon />
                </Avatar>
              }
              title={address.addr_street1}
              subheader={[
                address.addr_city,
                address.addr_state,
                address.addr_zip,
              ].join(", ")}
            />
          </Card>
        </Grid>
      )}
      <Grid mt={2} mb={1}>
        <Card>
          <CardHeader
            avatar={
              <Avatar sx={{ bgcolor: blueGrey[400] }}>
                <ContactPageIcon />
              </Avatar>
            }
            title={[contact.firstName, contact.lastName].join(" ")}
            subheader={phoneFmt(contact.phone)}
          />
        </Card>
      </Grid>
      <Grid mt={2} mb={1} item xs={12}>
        <PrescriberCard
          title="Patients"
          count={data.patients.length}
          diff={0}
          navigate={`/prescribers/${npi}/patients`}
        />
      </Grid>
      <DialogWrapper
        open={reassignRepOpen}
        closer={closeReassignRep}
        title="Reassign Rep"
      >
        <ReassignRep closer={closeReassignRep} />
      </DialogWrapper>
    </BaseLayout>
  );
}

export default Prescriber;
