// react-router components
import { Routes, Route, Navigate } from "react-router-dom";

import Profile from "./pages/profile";
import Prescribers from "./pages/prescribers";
import Prescriber from "./pages/prescribers/single";
import AddPrescriber from "./pages/prescribers/new";
import Enroll from "./pages/prescribers/enroll";
import Patients from "./pages/patients";
import Patient from "./pages/patients/single";
import Service from "./pages/service";
import Inventory from "./pages/inventory";
import InventoryType from "./pages/inventory/single";
// import Agency from "./pages/agency";
// import AgencyMetric from "./pages/agency/metric";
// import AgencyRep from "./pages/agency/rep"
import PatientAction from "./pages/patients/action";

import { Auth0Provider } from "@auth0/auth0-react";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import theme from "./assets/theme";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import RemoteAction from "./pages/RemoteAction";
import LoginGuard from "./pages/LoginGuard";
import Debug from "./pages/Debug";
import ServiceSingle from "./pages/service/single";
import Dashboard from "./pages/dashboard";
import PrescriberPatients from "./pages/prescribers/patients";

function App() {
  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      redirectUri={process.env.REACT_APP_AUTH0_REDIRECT_URL}
      audience={process.env.REACT_APP_AUTH0_AUDIENCE}
      useRefreshTokens={true}
      cacheLocation="localstorage"
    >
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routes>
          <Route element={<LoginGuard />}>
            <Route path="/debug" element={<Debug />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/dashboard" element={<Dashboard />} />
            {/*<Route path="/agency" element={ <Agency /> } />*/}
            {/*<Route path="/agency/rep/:id" element={ <AgencyRep /> } />*/}
            {/*<Route path="/agency/:metric/:year/:month" element={ <AgencyMetric /> } />*/}
            <Route path="/prescribers" element={<Prescribers />} />
            <Route path="/prescribers/new" element={<AddPrescriber />} />
            <Route
              path="/prescribers/:npi/enroll/:program"
              element={<Enroll />}
            />
            <Route
              path="/prescribers/:npi/patients"
              element={<PrescriberPatients />}
            />
            <Route path="/prescribers/:npi" element={<Prescriber />} />
            <Route path="/patients" element={<Patients />} />
            <Route
              path="/patients/:id/actions/:action"
              element={<PatientAction />}
            />
            <Route path="/patients/:id" element={<Patient />} />
            <Route path="/service" element={<Service />} />
            <Route path="/service/:id" element={<ServiceSingle />} />
            <Route path="/inventory" element={<Inventory />} />
            <Route path="/inventory/:id" element={<InventoryType />} />
          </Route>

          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/sign/:id" element={<RemoteAction />} />

          {/*build 404*/}
          <Route path="*" element={<Navigate to="/dashboard" />} />
        </Routes>
      </ThemeProvider>
    </Auth0Provider>
  );
}

export default App;
