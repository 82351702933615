import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import * as React from "react";
import {useState} from "react";

export default function ReassignRep({closer}) {
  const [disabled, setDisabled] = useState(true)

  function handleSubmit() {
    console.log('Reassign Rep Save Called')
    closer();
  }

  return (
    <Box m={2}>
      <Button onClick={() => setDisabled(false)}>it's done</Button>
      <Divider />
      <Stack direction="row" spacing={2}>
        <Button variant="contained" autoFocus color="inherit" onClick={() => handleSubmit()} disabled={disabled}>Submit</Button>
      </Stack>
    </Box>
  )
}