import Box from "@mui/material/Box";
import PatientForm from "../PatientForm";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import * as React from "react";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import {
  phoneFmt,
  formatDateMM_DD_YYYY,
  formatDateYYYY_MM_DD,
} from "../../formatters";
import { Alert, CircularProgress } from "@mui/material";

function getDevice(equipment) {
  return "Vasopneumatic compression and controlled thermal";
}

function diffDays(date1, date2) {
  const millisecondsPerDay = 1000 * 60 * 60 * 24;
  const diffInMilliseconds = date1 - date2;
  const diffInDays = Math.max(
    Math.ceil(diffInMilliseconds / millisecondsPerDay),
    0
  );

  return diffInDays === 1 ? diffInDays + " Day" : diffInDays + " Days";
}

export default function PickupUnit({ api, cycle: treat, closer }) {
  const { treat_id } = treat;
  const [doneShow, setDoneShow] = useState(false);
  const [sigs, setSigs] = useState({});
  const [apiData, setApiData] = useState(["init", {}]);
  const [status, data] = apiData;

  const today = new Date();
  const picked_up_on = formatDateMM_DD_YYYY(today);
  const picked_up_on_server = formatDateYYYY_MM_DD(today);

  useEffect(() => {
    if (status === "submit") {
      console.log("make api call");
      api
        .doCopa(treat_id, sigs, picked_up_on_server, pdfData)
        .then((d) => {
          setApiData(["loaded", d]);
        })
        .catch((error) => {
          setApiData(["error", error]);
        });

      setApiData(["submitting", {}]);
    }
    if (status === "submitting") {
    }
    if (status === "error") {
    }
    if (status === "loaded") {
      setTimeout(() => closer(), 100);
    }
  });

  function handleSubmit() {
    setApiData(["submit", {}]);
    console.log("submit Pickup");
  }

  const _fitting = new Date(treat.first_arrived_on);
  const _fitting_string = formatDateMM_DD_YYYY(_fitting);
  const days = diffDays(today, _fitting);

  const address = {
    addr_street1: treat.patient_street1,
    addr_street2: treat.patient_street2,
    addr_city: treat.patient_city,
    addr_state: treat.patient_state,
    addr_zip: treat.patient_zip,
  };

  const pdfData = {
    patient_name: treat.patient_name,
    address: address,
    phone: phoneFmt(treat.patient_phone),
    equipment: "VascuTherm 4",
    fit: _fitting_string,
    pickup: picked_up_on,
    days: days,
  };

  if (status === "submitting") {
    return (
      <Box
        sx={{
          width: 100,
          height: 100,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (status === "error") {
    return <Alert severity="error">{data.message}</Alert>;
  }

  return (
    <Box m={2}>
      <Stack spacing={1} my={2}>
        <Box>
          <Typography variant="overline">Patient Name:</Typography>
          <Typography variant="body2">{treat.patient_name}</Typography>
        </Box>
        <Box>
          <Typography variant="overline">Address:</Typography>
          <Typography variant="body2">{treat.patient_street1}</Typography>
          {treat.patient_street2 && (
            <Typography variant="body2">{treat.patient_street2}</Typography>
          )}
          <Typography variant="body2">
            {treat.patient_city}, {treat.patient_state}, {treat.patient_zip}
          </Typography>
        </Box>
        <Box>
          <Typography variant="overline">Phone:</Typography>
          <Typography variant="body2">
            {phoneFmt(treat.patient_phone)}
          </Typography>
        </Box>
      </Stack>
      <Divider sx={{ margin: "1em" }} />
      <Stack spacing={2} my={2}>
        <Box>
          <Typography variant="overline">Equipment Type:</Typography>
          <Typography variant="body2">VascuTherm 4</Typography>
        </Box>
        <Box>
          {/*Fitting Date is date of unit delivery (UPS / PSR) */}
          <Typography variant="overline">Delivery Date:</Typography>
          <Typography variant="body2">{_fitting_string}</Typography>
        </Box>
        <Box>
          {/*End Date is date of unit pickup (UPS / PSR) */}
          <Typography variant="overline">Pickup Date:</Typography>
          <Typography variant="body2">{picked_up_on}</Typography>
        </Box>
        <Box>
          <Typography variant="overline"># of Days:</Typography>
          <Typography variant="body2">{days}</Typography>
        </Box>
      </Stack>
      <Divider sx={{ margin: "1em" }} />
      <Box my={2}>
        <Typography variant="overline">
          I hereby acknowledge that the prescription rental for the{" "}
          {getDevice(data.equipment)} therapy device has been completed on:{" "}
          {picked_up_on}
        </Typography>
      </Box>
      <Divider sx={{ margin: "1em" }} />
      <Box>
        <PatientForm showComplete={setDoneShow} signatures={setSigs} />
        <Divider sx={{ margin: "1em" }} />
        <Button
          variant="contained"
          autoFocus
          color="inherit"
          disabled={!doneShow}
          onClick={() => handleSubmit()}
          fullWidth
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
}
