import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import BaseLayout from "../../layouts/BaseLayout/index.js";
import { Alert, CircularProgress, TextField } from "@mui/material";
import NpiCard from "../../components/NpiCard";
import Divider from "@mui/material/Divider";
import NpiCardReview from "../../components/NpiCardReview";
import { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";

const submissionStates = {
  init: "init",
  submitting: "submitting",
  success: "success",
  error: "error",
};

function AddPrescriber() {
  const [submissionState, setSubmissionState] = useState(submissionStates.init);
  const [activeStep, setActiveStep] = useState(0);
  const [errorMessage, setErrorMessage] = useState();
  const [fields, setFields] = useState({
    npi: "",
    preferredName: "",
    prescriberEmail: "",
    practiceName: "",
    practiceAddress: "",
    schedulerName: "",
    schedulerPhone: "",
    schedulerEmail: "",
  });
  const [npiFound, setNpiFound] = useState({});
  const { api } = useOutletContext();
  const [submissionResponse, setSubmissionResponse] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    if (
      activeStep === steps.length &&
      submissionState === submissionStates.init
    ) {
      setSubmissionState(submissionStates.submitting);
    }

    const data = {
      ...npiFound,
      ...fields,
    };

    if (submissionState === submissionStates.submitting) {
      api
        .addPrescriber(data)
        .then((d) => {
          setSubmissionResponse(d);
          setErrorMessage(null);

          setSubmissionState(submissionStates.success);
          navigate(`/prescribers/${npiFound.npi}`);
        })
        .catch((error) => {
          setSubmissionResponse(error);
          console.log(error.message);
          setErrorMessage(error.message.trim());
          setSubmissionState(submissionStates.error);
        });
    }

    if (submissionState === submissionStates.error) {
      setActiveStep(1);
      setSubmissionState(submissionStates.init);
    }
  }, [activeStep, submissionState]);

  const handleNext = () => {
    console.log("handling next...");
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const setField = (e) => {
    // const tmp = {e.target.id: e.target.value}
    const id = e.target.id;
    const val = e.target.value;
    const tmp = { [id]: val };
    setFields({ ...fields, ...tmp });
  };

  const myData = {
    ...npiFound,
    provided: fields,
  };

  const steps = [
    {
      label: "Lookup Prescriber",
      content: (
        <TextField
          id="npi"
          value={fields.npi}
          fullWidth
          onChange={setField}
          inputProps={{ inputMode: "numeric", pattern: "[0-9]{10}" }}
          label="NPI"
          variant="outlined"
          fullWidth
          autoFocus
          required
          helperText="National Provider Identifier"
        />
      ),
    },
    {
      label: "Add Details",
      content: (
        <Box>
          <NpiCard npi={fields.npi} setNpiFound={setNpiFound} />
          {npiFound.exists && (
            <Alert severity="warning">NPI has already been registered</Alert>
          )}
          {errorMessage && (
            <Alert severity="warning" sx={{ whiteSpace: "pre-line" }}>
              {errorMessage}
            </Alert>
          )}
          {npiFound.npi && !npiFound.exists && (
            <Stack spacing={2} mt={2}>
              <TextField
                id="preferredName"
                label="Preferred Name"
                value={fields.preferredName}
                fullWidth
                onChange={setField}
              />
              <TextField
                id="prescriberEmail"
                label="Prescriber Email"
                value={fields.prescriberEmail}
                fullWidth
                onChange={setField}
              />
              <Divider />
              <TextField
                required
                id="practiceName"
                label="Practice Name"
                value={fields.practiceName}
                fullWidth
                onChange={setField}
              />
              <TextField
                required
                id="practiceAddress"
                label="Practice Address"
                value={fields.practiceAddress}
                fullWidth
                onChange={setField}
              />
              <Divider />
              <TextField
                id="schedulerName"
                label="Scheduler Name"
                value={fields.schedulerName}
                fullWidth
                onChange={setField}
              />
              <TextField
                id="schedulerPhone"
                label="Scheduler Phone"
                value={fields.schedulerPhone}
                fullWidth
                onChange={setField}
              />
              <TextField
                id="schedulerEmail"
                label="Scheduler Email"
                value={fields.schedulerEmail}
                fullWidth
                onChange={setField}
              />
            </Stack>
          )}
          <Divider />
        </Box>
      ),
    },
    {
      label: "Review & Submit",
      content: <NpiCardReview data={myData} />,
    },
  ];

  return (
    <BaseLayout>
      <Box sx={{ width: "100%" }}>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel
                optional={
                  index === 2 ? (
                    <Typography variant="caption">Last step</Typography>
                  ) : null
                }
              >
                {step.label}
              </StepLabel>
              <StepContent>
                <Typography>{step.content}</Typography>
                <Box sx={{ mb: 2 }}>
                  <div>
                    <Button
                      variant="contained"
                      onClick={handleNext}
                      sx={{ mt: 1, mr: 1 }}
                      disabled={
                        (index === 1 && (!npiFound?.npi || npiFound.exists)) ||
                        (index === 1 &&
                          !(fields.practiceName && fields.practiceAddress))
                      }
                    >
                      {index === steps.length - 1 ? "Submit" : "Continue"}
                    </Button>
                    <Button
                      disabled={index === 0}
                      onClick={handleBack}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      Back
                    </Button>
                  </div>
                </Box>
              </StepContent>
            </Step>
          ))}
        </Stepper>
        {activeStep === steps.length &&
          submissionState === submissionStates.submitting && (
            <Paper square elevation={0} sx={{ p: 3 }}>
              <Typography>Adding Prescriber...</Typography>
              <CircularProgress />
              {/*<Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>Reset</Button>*/}
            </Paper>
          )}
        {activeStep === steps.length &&
          submissionState === submissionStates.success && (
            <Paper square elevation={0} sx={{ p: 3 }}>
              <Typography>New Prescriber Added</Typography>
              {/*<Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>Reset</Button>*/}
            </Paper>
          )}
      </Box>
    </BaseLayout>
  );
}

export default AddPrescriber;
