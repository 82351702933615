import * as React from "react";
import { useState } from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import DialogWrapper from "../../components/Actions/DialogWrapper";
import FitPatient from "../../components/Actions/FitPatient";
import DelayFitting from "../../components/Actions/DelayFitting";
import SchedulePickup from "../../components/Actions/SchedulePickup";
import Pickup from "../../components/Actions/Pickup";

function cycleToButtons(cycle) {
  const buttons = [];

  if (cycle.service_channel === "drop-ship") return buttons;

  if (!cycle.fit_on) {
    buttons.push("fit");
    buttons.push("schedule_fit");
  } else if (!cycle.picked_up_on) {
    buttons.push("pickup");
    buttons.push("schedule_pickup");
    buttons.push("replace_unit");
  }

  return buttons;
}

function ServiceCallToActions({ cycle, api, refresh }) {
  const [fitPatientOpen, setFitPatientOpen] = useState(false);
  const [delayFittingOpen, setDelayFittingOpen] = useState(false);
  const [schedulePickupOpen, setSchedulePickupOpen] = useState(false);
  const [pickupOpen, setPickupOpen] = useState(false);
  const closeDelayFitting = () => {
    setDelayFittingOpen(false);
    refresh(["init", []]);
  };
  const closeFitPatient = () => {
    setFitPatientOpen(false);
    refresh(["init", []]);
  };
  const closeSchedulePickup = () => {
    setSchedulePickupOpen(false);
    refresh(["init", []]);
  };
  const closePickup = () => {
    setPickupOpen(false);
    refresh(["init", []]);
  };

  const show = cycleToButtons(cycle);

  const buttons = {
    fit: (
      <Grid mt={1} mb={1} key="fit">
        <Button
          variant="contained"
          color="secondary"
          fullWidth
          p={4}
          onClick={() => setFitPatientOpen(true)}
        >
          Fit Patient
        </Button>
        <DialogWrapper
          open={fitPatientOpen}
          closer={closeFitPatient}
          title="Fit Patient"
        >
          <FitPatient api={api} cycle={cycle} closer={closeFitPatient} />
        </DialogWrapper>
      </Grid>
    ),
    schedule_fit: (
      <Grid mt={1} mb={1} key="schedule_fit">
        <Button
          variant="contained"
          color="secondary"
          fullWidth
          p={4}
          onClick={() => setDelayFittingOpen(true)}
        >
          Delay Fitting
        </Button>
        <DialogWrapper
          open={delayFittingOpen}
          closer={closeDelayFitting}
          title="Delay Patient Fitting"
        >
          <DelayFitting closer={closeDelayFitting} id={cycle.id} api={api} />
        </DialogWrapper>
      </Grid>
    ),
    pickup: (
      <Grid mt={1} mb={1} key="pickup">
        <Button
          variant="contained"
          color="secondary"
          fullWidth
          p={4}
          onClick={() => setPickupOpen(true)}
        >
          Pickup
        </Button>
        <DialogWrapper
          open={pickupOpen}
          closer={closePickup}
          title="Completion of Service"
        >
          <Pickup closer={closePickup} cycle={cycle} api={api} />
        </DialogWrapper>
      </Grid>
    ),
    schedule_pickup: (
      <Grid mt={1} mb={1} key="schedule_pickup">
        <Button
          variant="contained"
          color="secondary"
          fullWidth
          p={4}
          onClick={() => setSchedulePickupOpen(true)}
        >
          Schedule Pickup
        </Button>
        <DialogWrapper
          open={schedulePickupOpen}
          closer={closeSchedulePickup}
          title="Schedule Pickup"
        >
          <SchedulePickup
            closer={closeSchedulePickup}
            id={cycle.id}
            api={api}
          />
        </DialogWrapper>
      </Grid>
    ),
    // "replace_unit":
    //   <div key="replace_unit">
    //     replace unit
    //   </div>
  };

  return (
    <>
      {Object.keys(buttons)
        .filter((key) => show.includes(key))
        .map((key) => buttons[key])}
    </>
  );
}

export default ServiceCallToActions;
