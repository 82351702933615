import { useNavigate } from "react-router-dom";
import {
  Alert,
  Box,
  Card,
  CardHeader,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignature } from "@fortawesome/pro-regular-svg-icons";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

function PrescriberList({ rxers, compressed = false }) {
  const navigate = useNavigate();

  let lastLetter = "";
  let section = "";

  if (rxers.length === 0) {
    return (
      <Alert severity="error">
        You have no prescribers to display here... but I have a feeling you will
        soon!
      </Alert>
    );
  }

  return rxers.map((rec) => {
    const newLetter = rec.lastName.substr(0, 1);
    if (lastLetter !== newLetter) {
      section = <Box m={1}>{newLetter}</Box>;
      lastLetter = newLetter;
    } else {
      section = "";
    }
    const cct = rec.sigs.includes("cct") ? (
      <FontAwesomeIcon icon={faSignature} color="lightskyblue" size="xs" />
    ) : (
      ""
    );
    const sam = rec.sigs.includes("sam") ? (
      <FontAwesomeIcon icon={faSignature} color="lightgreen" size="xs" />
    ) : (
      ""
    );

    return (
      <Box key={rec.npi} mt={0}>
        {!compressed && section}
        <Card>
          <CardHeader
            title={
              <Typography variant="h5">
                {rec.lastName}, {rec.firstName}
              </Typography>
            }
            subheader={
              <Typography variant="subtitle2">{rec.practice}</Typography>
            }
            action={
              <Stack direction="row" spacing={1}>
                {cct}
                {sam}
                <IconButton
                  size="small"
                  onClick={() => navigate(`/prescribers/${rec.npi}`)}
                >
                  <ArrowForwardIosIcon />
                </IconButton>
              </Stack>
            }
          />
        </Card>
      </Box>
    );
  });
}

export function PrescriberListSkeleton() {
  return <div>loading...</div>;
}

export default PrescriberList;
