import { useState } from "react";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import BaseLayout from "../../layouts/BaseLayout/index.js";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

function patientActionsForm(value, handleChange) {
  return (
    <Box>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <MobileDatePicker
          label="Date"
          inputFormat="MM/DD/YYYY"
          value={value}
          onChange={handleChange}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
      <TextField
        id="outlined-multiline-static"
        label="Multiline"
        multiline
        rows={4}
        defaultValue="Default Value"
      />
    </Box>
  );
}

export default function PatientAction() {
  const { id, action } = useParams();

  const [value, setValue] = useState(dayjs("2014-08-18T21:11:54"));
  const handleChange = (newValue) => {
    setValue(newValue);
  };

  // const Form = patientActionsForm(value, handleChange)
  // const Form = <div>hi</div>

  return (
    <BaseLayout>
      <Typography variant="h3" align="center">
        {id} - {action}
      </Typography>
      {/*<Form />*/}
    </BaseLayout>
  );
}
