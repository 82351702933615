import BaseLayout from "../../layouts/BaseLayout/index.js";
import PatientList from "../../components/PatientList";
import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import Paper from "@mui/material/Paper";
import { InputBase, Alert } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";

function Patients() {
  const [search, setSearch] = useState("");
  const [[status, data], setPatientData] = useState(["init", []]);
  const { api } = useOutletContext();

  useEffect(() => {
    if (status === "init") {
      api
        .getPatients()
        .then((d) => {
          d.sort((a, b) => a.lastName > b.lastName);
          setPatientData(["loaded", d]);
        })
        .catch((error) => {
          setPatientData(["error", error]);
        });

      setPatientData(["loading", []]);
    }
  }, [status, api]);

  const pats = data.filter((v) => {
    return (
      v.firstName.toLowerCase().startsWith(search.toLowerCase()) ||
      v.lastName.toLowerCase().startsWith(search.toLowerCase()) ||
      v.city.toLowerCase().startsWith(search.toLowerCase()) ||
      search === ""
    );
  });

  const onChange = (event) => {
    setSearch(event.target.value);
  };

  return (
    <BaseLayout>
      <Paper
        component="form"
        sx={{ p: "2px 4px", display: "flex", alignItems: "center" }}
      >
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Filter Patients"
          onChange={onChange}
          value={search}
        />
        <IconButton sx={{ p: "10px" }} aria-label="search">
          <SearchIcon />
        </IconButton>
      </Paper>
      {status === "error" ? (
        <div>Error Loading Patient Data</div>
      ) : (
        <PatientList patients={pats} />
      )}
    </BaseLayout>
  );
}

export default Patients;
