import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import Box from "@mui/material/Box";
import {Alert} from "@mui/material";
import Enroll from "../components/Enroll";
import * as React from "react";
import {wrsCentralNoAuth} from "../noAuthApi";

export default function RemoteAction() {
  const { id } = useParams();
  const api = wrsCentralNoAuth(id);

  const [apiResponse, setApiResponse] = useState(['init', {}])
  const [status, data] = apiResponse;

  const [thankYou, setThankYou] = useState(false)

  useEffect(() => {
    if (api && status === 'init') {
      api.getTok(id)
        .then((d) => {setApiResponse(['loaded', d])})
        .catch((error) => {setApiResponse(['error', error])})
    }
  })

  console.log(data);

  function onCompleteCct() {
    setThankYou(true);
  }

  function onCompleteSam() {
    setThankYou(true);
  }

  const EnrollCct = () => {
    return (
      <Enroll program="cct" npi={data.target_ident} blankRx={{location: '/cct-blank.pdf'}} onComplete={onCompleteCct} api={api.enrollNpiVt4} />
    );
  }

  const EnrollSam = () => {
    return (
      <Enroll program="sam" npi={data.target_ident} blankRx={{location: '/sam-blank.pdf'}} onComplete={onCompleteSam} api={api.enrollNpiSam} />
    );
  }

  const SignPic = () => {
    return <div>Let's do a PIC</div>
  }

  const SignCopa = () => {
    return <div>Let's do a COPA</div>
  }


  const purposes = {
    "enroll_vt4": <EnrollCct />,
    "enroll_sam": <EnrollSam />,
    "pic": <SignPic />,
    "copa": <SignCopa />
  }

  if (thankYou) {
    return (
      <Box m={2}>
        <Alert severity="success">Thank you for signing, our team has been notified and will follow up as needed.</Alert>
      </Box>
    )
  }

  if (status === 'error') {
    return (
      <Box m={2}>
        <Alert severity="error">This link appears to be invalid.  Please reach out to WRS to remedy.</Alert>
      </Box>
    )
  }


  if (status === 'loaded') {
    return (
      <Box p={2}>
        {purposes[data.purpose]}
      </Box>
    )
  }

  return null;
}