import * as React from 'react';

// @mui material components
import Paper from "@mui/material/Paper";
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBedPulse } from '@fortawesome/free-solid-svg-icons'
import {faBuilding, faStethoscope, faBoxesStacked, faList, faHome} from '@fortawesome/pro-solid-svg-icons'
import {useNavigate} from "react-router-dom";

import { styled } from "@mui/material/styles";

const MyBottomNavigationAction = styled(BottomNavigationAction)(`
  color: grey;
  &.Mui-selected {
    color: lightskyblue;
  }
`);


function Footer() {
  const paths = window.location.pathname.split('/');
  const path = paths[1] ? paths[1] : '';
  const navigate = useNavigate();
  const [footerNavValue, setFooterNavValue] = React.useState(path);

  const handleChange = (event, newValue) => {
    setFooterNavValue(newValue);
    navigate(`/${newValue}`)
  };

  return (
    <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 1000 }} elevation={3}>
      <BottomNavigation sx={{ width: "100%"}} value={footerNavValue} onChange={handleChange}>
        <MyBottomNavigationAction
          value="dashboard"
          icon={<FontAwesomeIcon icon={faHome} size="lg" />}
        />
        <MyBottomNavigationAction
          value="prescribers"
          icon={<FontAwesomeIcon icon={faStethoscope} size="lg" />}
        />
        <MyBottomNavigationAction
          value="patients"
          icon={<FontAwesomeIcon icon={faBedPulse} size="lg" />}
        />
        <MyBottomNavigationAction
          value="service"
          icon={<FontAwesomeIcon icon={faList} size="lg" />}
        />
        <MyBottomNavigationAction
          value="inventory"
          icon={<FontAwesomeIcon icon={faBoxesStacked} size="lg" />}
        />
      </BottomNavigation>
    </Paper>
  );
}

export default Footer;
