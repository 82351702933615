import { useState } from "react";
import { Card, CardContent, Stack, TextField, Typography } from "@mui/material";
import Button from "@mui/material/Button";

/**
 * @param {string} val
 * @returns {[boolean, string, number|null]}
 */
const examineFormVal = (val) => {
  if (val === "") return [false, " ", null];
  const parsed = parseInt(val, 10);
  if (isNaN(parsed)) {
    return [true, "This does not look like a valid rep id", null];
  }

  if (parsed <= 0) {
    return [true, "This does not look like a valid rep id", null];
  }

  if (parsed.toString() !== val) {
    return [true, "This does not look like a valid rep id", null];
  }

  return [false, " ", parsed];
};

/**
 * @param {function} onImpersonateCommit
 */
const RepIdOverrideForm = ({ onImpersonateCommit }) => {
  const [formVal, setFormVal] = useState("");

  const [error, helperText, repId] = examineFormVal(formVal);
  const onSubmit = (e) => {
    onImpersonateCommit(repId);
    e.preventDefault();
  };

  return (
    <Card sx={{ m: 2 }} component="form" onSubmit={onSubmit}>
      <CardContent>
        <Stack spacing={2}>
          <Typography>
            Your user is not setup for central. However! You've been blessed
            with an impersonation privilege. You may enter a rep id and
            impersonate them for this login session:
          </Typography>
          <TextField
            label="Rep ID"
            name="rep_id"
            value={formVal}
            error={error}
            helperText={helperText}
            onChange={(e) => setFormVal(e.target.value)}
          />
          <Button type="submit">Impersonate</Button>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default RepIdOverrideForm;
