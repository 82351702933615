import { useState, useEffect } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { Box, Stack, IconButton, Fab, InputBase } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";

import BaseLayout from "../../layouts/BaseLayout/index.js";
import Paper from "@mui/material/Paper";
import PrescriberList from "../../components/PrescriberList";

function Prescribers() {
  const [search, setSearch] = useState("");
  const [[status, data], setPrescriberData] = useState(["init", []]);
  const { api } = useOutletContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (status === "init") {
      api
        .getPrescribers()
        .then((d) => {
          d.sort((a, b) => a.lastName > b.lastName);
          setPrescriberData(["loaded", d]);
        })
        .catch((error) => {
          setPrescriberData(["error", error]);
        });
      setPrescriberData(["loading", []]);
    }
  }, [status, api]);

  const rxers = data.filter((v) => {
    return (
      v.firstName.toLowerCase().startsWith(search.toLowerCase()) ||
      v.lastName.toLowerCase().startsWith(search.toLowerCase()) ||
      search === ""
    );
  });

  const onChange = (event) => {
    setSearch(event.target.value);
  };

  return (
    <BaseLayout>
      <Box
        sx={{ position: "fixed", bottom: "60px", right: "10px", zIndex: "10" }}
      >
        <Fab
          color="secondary"
          aria-label="add"
          size="small"
          onClick={() => navigate(`/prescribers/new`)}
        >
          <AddIcon />
        </Fab>
      </Box>
      <Paper
        component="form"
        sx={{ p: "2px 4px", display: "flex", alignItems: "center" }}
      >
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Filter Prescribers"
          onChange={onChange}
          value={search}
        />
        <IconButton sx={{ p: "10px" }} aria-label="search">
          <SearchIcon />
        </IconButton>
      </Paper>
      <Box sx={{ width: "100%" }}>
        <Stack spacing={1}>
          {status === "error" ? (
            <div>Error</div>
          ) : (
            <PrescriberList rxers={rxers} />
          )}
        </Stack>
      </Box>
    </BaseLayout>
  );
}

export default Prescribers;
