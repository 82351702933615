import { useEffect, useState } from "react";
import BaseLayout from "../../layouts/BaseLayout/index.js";
import PatientList from "../../components/PatientList";
import { useOutletContext, useParams } from "react-router-dom";
import { InputBase, Alert, Typography, Paper } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";

function PrescriberPatients() {
  let { npi } = useParams();

  const [search, setSearch] = useState("");
  const [[status, data], setPatientData] = useState(["init", []]);
  const [rxer, setRxer] = useState({});
  const { api } = useOutletContext();

  useEffect(() => {
    if (status === "init") {
      api
        .getPrescriber(npi)
        .then((res) => {
          const d = res.patients;
          d.sort((a, b) => a.lastName > b.lastName);
          setRxer(res);
          setPatientData(["loaded", d]);
        })
        .catch((error) => {
          setPatientData(["error", error]);
        });

      setPatientData(["loading", []]);
    }
  }, [status, api]);

  const pats = data.filter((v) => {
    return (
      v.firstName.toLowerCase().startsWith(search.toLowerCase()) ||
      v.lastName.toLowerCase().startsWith(search.toLowerCase()) ||
      v.city.toLowerCase().startsWith(search.toLowerCase()) ||
      search === ""
    );
  });

  const onChange = (event) => {
    setSearch(event.target.value);
  };

  if (["init", "loading"].includes(status)) return <BaseLayout></BaseLayout>;

  if (pats.length === 0) {
    return (
      <BaseLayout>
        <Alert severity="error">
          You have no patients to display here... go make the rounds!
        </Alert>
      </BaseLayout>
    );
  }

  return (
    <BaseLayout>
      <Typography variant="h3" align="center">
        {[rxer.firstName, rxer.lastName, rxer.credential].join(" ")}
      </Typography>
      <Typography variant="h6" align="center">
        Patient List
      </Typography>
      <Paper
        component="form"
        sx={{ p: "2px 4px", display: "flex", alignItems: "center", mt: 1 }}
      >
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Filter Patients"
          onChange={onChange}
          value={search}
        />
        <IconButton sx={{ p: "10px" }} aria-label="search">
          <SearchIcon />
        </IconButton>
      </Paper>
      {status === "error" ? (
        <div>Error Loading Patient Data</div>
      ) : (
        <PatientList patients={pats} />
      )}
    </BaseLayout>
  );
}

export default PrescriberPatients;
