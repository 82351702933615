import {useAuth0} from "@auth0/auth0-react";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";

export default function Logout() {
  const { logout, isAuthenticated, isLoading } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    console.log('Is Authenticated: ' + isAuthenticated);
    console.log('Is Loading: ' + isLoading);
    if (isAuthenticated && !isLoading) logout();
    else if (!isLoading) {
      navigate('/');
    }
  }, [isAuthenticated, isLoading])

  return (
    <div>Logging Out...</div>
  )
}