import BaseLayout from "../../layouts/BaseLayout/index.js";
import {
  Typography,
  Grid,
  Card,
  Box,
  CardContent,
  CardHeader,
  Stack,
  Button,
  Alert,
} from "@mui/material";
import ColoredCard from "../../components/ColoredCard";
import { useNavigate, useOutletContext } from "react-router-dom";
import PatientList from "../../components/PatientList";
import PrescriberList from "../../components/PrescriberList";
import { useEffect, useState } from "react";
import { moneyFmt } from "../../formatters";

function StatCard(props) {
  const { sub, num } = props;
  return (
    <Grid item xs={6}>
      <Card>
        <CardContent sx={{ padding: 1, "&:last-child": { pb: 1 } }}>
          <Box display="flex">
            <Box m="auto">
              <Typography variant="h2">{num}</Typography>
            </Box>
          </Box>
          <Box display="flex">
            <Box m="auto">
              <Typography variant="caption" color="text.secondary">
                {sub}
              </Typography>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
}

function Activity(props) {
  const { pats, rxers } = props;
  return (
    <Box mt={2}>
      <Typography
        mt={0}
        mb={1}
        variant="h6"
        align="center"
        textTransform="uppercase"
      >
        Activity
      </Typography>
      <Grid
        container
        direction="row"
        justifyContent="space-evenly"
        alignItems="center"
        spacing={2}
      >
        <StatCard sub="Patients" num={pats} />
        <StatCard sub="Active Prescribers" num={rxers} />
      </Grid>
    </Box>
  );
}

function Reports(props) {
  const { api } = useOutletContext();
  const [runReport, setRunReport] = useState(null);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const { roles } = props;

  useEffect(() => {
    if (runReport) {
      const apiFunc =
        runReport === "weekly" ? api.ezReportWeekly : api.ezReport;
      apiFunc(runReport)
        .then((d) => {
          console.log(d);
          setSuccess("Your report has been emailed");
          setError(null);
          setRunReport(null);
        })
        .catch((e) => {
          console.log(e);
          setSuccess(null);
          setError("There was an error emailing your report");
          setRunReport(null);
        });
    }
  }, [runReport, api.ezReport, api.ezReportWeekly]);

  function report(type) {
    setRunReport(type);
    console.log(type);
  }

  const buttons = [
    {
      label: "Rx Report",
      value: "rx_status",
      roles: ["agent", "principal", "sales"],
    },
    {
      label: "Fitting Report",
      value: "fitting",
      roles: ["agent", "principal", "service"],
    },
    {
      label: "Pickup Report",
      value: "pickups",
      roles: ["agent", "principal", "service"],
    },
    {
      label: "Inventory Report",
      value: "inventory",
      roles: ["agent", "principal"],
    },
    {
      label: "Weekly Report",
      value: "weekly",
      roles: ["agent", "principal"],
    },
  ];

  return (
    <Box mt={2}>
      <Typography
        mt={0}
        mb={1}
        variant="h6"
        align="center"
        textTransform="uppercase"
      >
        Reports
      </Typography>
      <Stack spacing={1}>
        {success && <Alert severity="success">{success}</Alert>}
        {error && <Alert severity="error">{error}</Alert>}
        {buttons.map(
          (button) =>
            roles.some((role) => button.roles.includes(role)) && (
              <Button
                key={button.value}
                variant="contained"
                color="secondary"
                fullWidth
                p={4}
                onClick={() => report(button.value)}
                disabled={runReport === button.value}
              >
                {button.label}
              </Button>
            )
        )}
      </Stack>
    </Box>
  );
}

function Materials(props) {
  return (
    <Box mt={2}>
      <Typography
        mt={0}
        mb={1}
        variant="h6"
        align="center"
        textTransform="uppercase"
      >
        Links
      </Typography>
      <Stack spacing={1}>
        <Button
          key="materials"
          variant="contained"
          color="secondary"
          fullWidth
          p={4}
          onClick={() => window.open("https://materials.wrs.us")}
        >
          WRS Materials
        </Button>
      </Stack>
    </Box>
  );
}

function Commissions(props) {
  const { entries } = props;

  const total = entries.reduce((acc, cur) => acc + cur.amount, 0);
  const qty = entries.length;
  const item = qty === 1 ? "Item" : "Items";

  const navigate = useNavigate();

  return (
    <Box mt={2}>
      <Typography
        mt={0}
        mb={1}
        variant="h6"
        align="center"
        textTransform="uppercase"
      >
        Commissions
      </Typography>
      <Grid item xs={12} justifyContent="space-evenly" alignItems="center">
        <ColoredCard status="success" xs={12}>
          <CardHeader
            sx={{ paddingBottom: 0 }}
            title={<Typography variant="h5">{moneyFmt(total)}</Typography>}
          />
          <Box pl={2} pb={2}>
            <Typography variant="button" fontWeight="bold" color="green">
              {qty}{" "}
              <Typography
                variant="button"
                fontWeight="regular"
                color="secondary"
              >
                {item}
              </Typography>
            </Typography>
          </Box>
        </ColoredCard>
      </Grid>
    </Box>
  );
}

function Roster(props) {
  const { roster } = props;
  const navigate = useNavigate();
  const cards = roster.map((v) => {
    return (
      <Card xs={12} key={v.id}>
        <CardHeader
          sx={{ paddingBottom: 0 }}
          title={<Typography variant="h5">{v.name}</Typography>}
          // action={
          //   <Stack direction="row" spacing={1}>
          //     <IconButton size="small" onClick={() => navigate(`/agency/rep/${v.id}`)}>
          //       <ArrowForwardIosIcon/>
          //     </IconButton>
          //   </Stack>
          // }
        />
        <Box pl={2} pb={1}>
          <Typography variant="button" fontWeight="bold">
            <Typography variant="button" fontWeight="regular" color="secondary">
              {v.role}
            </Typography>
          </Typography>
        </Box>
      </Card>
    );
  });
  return (
    <Box mt={2}>
      <Typography
        mt={0}
        mb={1}
        variant="h6"
        align="center"
        textTransform="uppercase"
      >
        Roster
      </Typography>
      <Stack spacing={1}>{cards}</Stack>
    </Box>
  );
}

function Prescribers(props) {
  const { rxers } = props;
  return (
    <Box mt={2}>
      <Typography
        mt={0}
        mb={1}
        variant="h6"
        align="center"
        textTransform="uppercase"
      >
        Active Prescribers
      </Typography>
      <Stack spacing={1}>
        <PrescriberList rxers={rxers} compressed={true} />
      </Stack>
    </Box>
  );
}

function Patients(props) {
  const { pats } = props;
  return (
    <Box mt={2}>
      <Typography
        mt={0}
        mb={1}
        variant="h6"
        align="center"
        textTransform="uppercase"
      >
        Patients
      </Typography>
      <PatientList patients={pats} dense={true} />
    </Box>
  );
}

function CentralDashboard() {
  const [[status, data], setApiData] = useState(["init", {}]);
  const { api, me } = useOutletContext();

  console.log(me);

  useEffect(() => {
    if (status === "init") {
      api
        .getDashboard()
        .then((d) => {
          setApiData(["loaded", d]);
        })
        .catch((error) => {
          setApiData(["error", error]);
        });

      setApiData(["loading", []]);
    }
  }, [status, api]);

  if (status === "loaded") {
    const patCount = data.patients.length;
    const rxerCount = data.prescribers.length;
    return (
      <BaseLayout>
        <Typography variant="h3" align="center">
          {data.title}
        </Typography>
        <Activity pats={patCount} rxers={rxerCount} />
        <Reports roles={me.roles} />
        <Materials />
        {data.commissions && <Commissions entries={data.commissions} />}
        {data.roster && <Roster roster={data.roster} />}
        {data.patients && <Patients pats={data?.patients} />}
        {data.prescribers && <Prescribers rxers={data?.prescribers} />}
      </BaseLayout>
    );
  }

  return (
    <BaseLayout>
      <Box>
        <Typography variant="h3" align="center">
          loading...
        </Typography>
      </Box>
    </BaseLayout>
  );
}

export default CentralDashboard;
