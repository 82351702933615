import {useAuth0} from "@auth0/auth0-react";
import Button from "@mui/material/Button";
import {Container} from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

export default function Home() {
  const { loginWithRedirect } = useAuth0();

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          minHeight: '100vh',
          justifyContent: 'center',
          textAlign: 'center',
          pt: 8,
          pb: 8,
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4" component="h1" gutterBottom>
              WRS Central
            </Typography>
            <img src={`${process.env.PUBLIC_URL}/iceman.svg`} alt="WRS Central Iceman" width="200px" />
            <Typography variant="h6" gutterBottom>
              Please log in to continue
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={loginWithRedirect}
            >
              Log in
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Container>
  )
}