export const phoneFmt = (ipt) => {
  if (!ipt) {
    return "";
  }

  const hoesInDifferentAreaCodes = ipt.substring(0, 3);
  const exchange = ipt.substring(3, 6);
  const number = ipt.substring(6, 10);
  const extension = ipt.substring(10);

  return `(${hoesInDifferentAreaCodes}) ${exchange}-${number}${
    extension ? ` x${extension}` : ""
  }`;
};

export const moneyFmt = (ipt) => {
  if (!ipt) return "$0.00";
  if (isNaN(ipt)) return "$0.00";

  return ipt.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
};

export const dateFmt = (ipt) => {
  const x = new Date(ipt);
  return x.toDateString();
};

export function formatDateMM_DD_YYYY(date) {
  let day = date.getDate();
  let month = date.getMonth() + 1; // Months are 0-11, add 1 to get 1-12
  const year = date.getFullYear();

  // Pad the month and day with leading zeros, if necessary
  if (day < 10) day = "0" + day;
  if (month < 10) month = "0" + month;

  return month + "/" + day + "/" + year;
}

export function formatDateYYYY_MM_DD(date) {
  const year = date.getFullYear();
  const month = ("0" + (date.getMonth() + 1)).slice(-2); // Months are 0 indexed so January is 0, February is 1, etc.
  const day = ("0" + date.getDate()).slice(-2);

  return `${year}-${month}-${day}`;
}

export function treatSitesLabels(treatSites = []) {
  const siteOpts = [
    { label: "Right Shoulder", value: "shoulder-right" },
    { label: "Left Shoulder", value: "shoulder-left" },
    { label: "Right Knee", value: "knee-right" },
    { label: "Left Knee", value: "knee-left" },
    { label: "Right Ankle", value: "ankle-right" },
    { label: "Left Ankle", value: "ankle-left" },
    { label: "Right Arm", value: "arm-right" },
    { label: "Left Arm", value: "arm-left" },
    { label: "Lumbar", value: "lumbar" },
    { label: "Right Leg", value: "leg-right" },
    { label: "Left Leg", value: "leg-left" },
    { label: "Right Wrist", value: "wrist-right" },
    { label: "Left Wrist", value: "wrist-left" },
    { label: "Right Finger", value: "finger-right" },
    { label: "Left Finger", value: "finger-left" },
    { label: "Right Elbow", value: "elbow-right" },
    { label: "Left Elbow", value: "elbow-left" },
    { label: "Cervical", value: "cervical" },
    { label: "Hip", value: "hip" },
  ];

  const treatLabels = treatSites
    .map((selected) => {
      const foundOption = siteOpts.find((option) => option.value === selected);
      return foundOption ? foundOption.label : null;
    })
    .filter(Boolean); // remove any nulls in case a value wasn't found

  return treatLabels;
}
