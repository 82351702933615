import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import Footer from "../footer/index.js";
import Header from "../header/index.js";

function BaseLayout({ children }) {
  return (
    <Box sx={{ width: "100%" }} mb={8}>
      <Header />
      <Box p={1}>{children}</Box>
      <Footer />
    </Box>
  );
}

// Typechecking props for BaseLayout
BaseLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BaseLayout;
