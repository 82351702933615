import Paper from "@mui/material/Paper";

const LoadingAbortPaper = ({ children }) => {
  return (
    <Paper sx={{ maxWidth: "600px", width: "100%", p: 2, mt: 5, mx: "auto" }}>
      {children}
    </Paper>
  );
};

export default LoadingAbortPaper;
