import { createMachine, sendParent, send, assign } from "xstate";
import { useMachine } from "@xstate/react";
import Button from "@mui/material/Button";

const SignaturesMachine = createMachine({
  id: 'sigMachine',
  initial: 'clear',
  predictableActionArguments: true,
  context: {
    sig: 'starting value',
  },
  states: {
    clear: {
      entry: [
        sendParent('CLEARED'),
        assign({sig: 'cleared'}),
      ],
      on: {
        CLEAR: { target: 'clear' },
        TOUCH: { target: 'dirty' }
      }
    },
    dirty: {
      on: {
        CLEAR: { target: 'clear' },
        ACCEPT: { target: 'accepted' },
      },
    },
    accepted: {
      entry: [
        assign({sig: 'accepted sig here'}),
        sendParent('X') // @todo: IS THIS A RACE CONDITION?  WHY DO I HAVE TO SEND SOMETHING HERE???
      ],
      on: {
        CLEAR: { target: 'clear' },
        COMMIT: { target: 'commit' },
      }
    },
    commit: {
      type: 'final',
      data: (context) => ({
        sig: context.sig,
      }),
    }
  }
})

const FitPatientMachine = createMachine({
  id: 'fitPatient',
  initial: 'pickUnit',
  predictableActionArguments: true,
  context: {
    unit: '',
    patientSig: '',
    serviceSig: '',
  },
  invoke: [
    {
      id: 'patientSignature',
      src: SignaturesMachine,
      onDone: {
        actions: assign({ patientSig: (context, event) => event.data.sig })
      }
    },
    {
      id: 'serviceRepSignature',
      src: SignaturesMachine,
      onDone: {
        actions: assign({ serviceSig: (context, event) => event.data.sig })
      }
    },
  ],
  states: {
    pickUnit: {
      entry: [
        assign({ unit: '' }),
        send( {type: 'CLEAR'}, {to: 'patientSignature'} ),
        send( {type: 'CLEAR'}, {to: 'serviceRepSignature'} ),
      ],
      on: {
        PICKED: {
          actions: assign({ unit: 'A12345' }),
          target: 'getSignatures'
        }
      }
    },
    getSignatures: {
      on: {
        TOUCH_PATIENT: { actions: send( {type: 'TOUCH'}, {to: 'patientSignature'}) },
        CLEAR_PATIENT: { actions: send( {type: 'CLEAR'}, {to: 'patientSignature'}) },
        ACCEPT_PATIENT: { actions: send( {type: 'ACCEPT'}, {to: 'patientSignature'}) },
        TOUCH_REP: { actions: send( {type: 'TOUCH'}, {to: 'serviceRepSignature'}) },
        CLEAR_REP: { actions: send( {type: 'CLEAR'}, {to: 'serviceRepSignature'}) },
        ACCEPT_REP: { actions: send( {type: 'ACCEPT'}, {to: 'serviceRepSignature'}) },
      },
      always: {
        target: 'complete',
        cond: (context, event, meta) => {
          return (meta.state.children.patientSignature?.state.matches('accepted')
              && meta.state.children.serviceRepSignature?.state.matches('accepted'))
        }
      }
    },
    complete: {
      on: {
        CLEAR_PATIENT: { actions: send( {type: 'CLEAR'}, {to: 'patientSignature'}) },
        CLEAR_REP: { actions: send( {type: 'CLEAR'}, {to: 'serviceRepSignature'}) },
        COMMIT: {
          actions: [
            send( {type: 'COMMIT'}, {to: 'patientSignature'}),
            send( {type: 'COMMIT'}, {to: 'serviceRepSignature'})
          ],
          target: 'submitting'
        }
      },
      always: {
        target: 'getSignatures',
        cond: (context, event, meta) => {
          return !(meta.state.children.patientSignature?.state.matches('accepted')
            && meta.state.children.serviceRepSignature?.state.matches('accepted'))
        }
      }
    },
    submitting: {
      on: {
        SUCCESS: { target: 'success' },
        ERROR: { target: 'error' }
      }
    },
    success: {

    },
    error: {

    }
  }
})

const pickUnit = (send) => {
  return (
    <Button onClick={() => send('PICKED')}>Pick the Unit</Button>
  )
}

const getSignatures = (state, send) => {
  return (
    <div>
      <div>PATIENT:</div>
      {state.children.patientSignature?.state.matches('clear') && <Button onClick={ () => send('TOUCH_PATIENT')}>Touch Patient Sig</Button>}
      {state.children.patientSignature?.state.matches('dirty') && <Button onClick={ () => send('CLEAR_PATIENT')}>Clear Patient Sig</Button>}
      {state.children.patientSignature?.state.matches('dirty') && <Button onClick={ () => send('ACCEPT_PATIENT')}>Accept Patient Sig</Button>}
      {state.children.patientSignature?.state.matches('accepted') && <Button onClick={ () => send('CLEAR_PATIENT')}>Clear Patient Accepted Sig</Button>}
      <div>SERVICE REP:</div>
      {state.children.serviceRepSignature?.state.matches('clear') && <Button onClick={ () => send('TOUCH_REP')}>Touch Service Rep Sig</Button>}
      {state.children.serviceRepSignature?.state.matches('dirty') && <Button onClick={ () => send('CLEAR_REP')}>Clear Service Rep Sig</Button>}
      {state.children.serviceRepSignature?.state.matches('dirty') && <Button onClick={ () => send('ACCEPT_REP')}>Accept Service Rep Sig</Button>}
      {state.children.serviceRepSignature?.state.matches('accepted') && <Button onClick={ () => send('CLEAR_REP')}>Clear Service Rep Accepted Sig</Button>}
    </div>
  )
}

const completeOptions = (state, send) => {
  return (
    <div>
      <div>PATIENT:</div>
      {state.children.patientSignature?.state.matches('accepted') && <Button onClick={ () => send('CLEAR_PATIENT')}>Clear Patient Accepted Sig</Button>}
      <div>SERVICE REP:</div>
      {state.children.serviceRepSignature?.state.matches('accepted') && <Button onClick={ () => send('CLEAR_REP')}>Clear Service Rep Accepted Sig</Button>}
    </div>
  )
}

function Debug () {
  const [state, send] = useMachine(FitPatientMachine)
  return (
    <div>
      <div>Status: {state.value}</div>
      <div>Unit: {state.context.unit}</div>

      <div>Patient Sig Status: {state.children.patientSignature?.state.value}</div>
      <div>Patient Sig Data: {state.context.patientSig}</div>

      <div>Service Rep Sig Status: {state.children.serviceRepSignature?.state.value}</div>
      <div>Service Rep Sig Data: {state.context.serviceSig}</div>

      {state.matches('pickUnit') && pickUnit(send)}
      {state.matches('getSignatures') && getSignatures(state, send)}
      {state.matches('complete') && completeOptions(state, send)}

      <div><Button disabled={!state.matches('complete')} onClick={ () => send('COMMIT')}>Submit</Button></div>
    </div>
  )
}

export default Debug;