import * as React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import Typography from "@mui/material/Typography";

const agency = [
  {
    event: "created_on",
    name: "Created",
    date: "",
    done: false,
    compliant: true,
  },
  {
    event: "fit_on",
    name: "Fitting",
    sub: "Schd: 05/05/2023",
    date: "",
    done: false,
    compliant: true,
  },
  {
    event: "service_start_on",
    name: "Service Start",
    date: "",
    done: false,
    compliant: true,
  },
  {
    event: "service_end_on",
    name: "Service End",
    date: "",
    done: false,
    compliant: true,
  },
  {
    event: "picked_up_on",
    name: "Collected",
    date: "",
    done: false,
    compliant: true,
  },
];
const dropShip = [
  {
    event: "created_on",
    name: "Created",
    date: "",
    done: false,
    compliant: true,
  },
  {
    event: "shipped_on",
    name: "Shipped",
    date: "",
    done: false,
    compliant: true,
  },
  {
    event: "arrived_on",
    name: "Delivered",
    date: "",
    done: false,
    compliant: true,
  },
  {
    event: "fit_on",
    name: "Fitting",
    date: "",
    done: false,
    compliant: true,
  },
  {
    event: "service_start_on",
    name: "Service Start",
    date: "",
    done: false,
    compliant: true,
  },
  {
    event: "service_end_on",
    name: "Service End",
    date: "",
    done: false,
    compliant: true,
  },
  {
    event: "picked_up_on",
    name: "Collected",
    date: "",
    done: false,
    compliant: true,
  },
];

function normalizeDate(date) {
  if (typeof date === "string") {
    const match = date.match(/^(\d{4})-(\d{2})-(\d{2})/);
    if (match) {
      return match[0];
    } else {
      const dateObject = new Date(date);
      return dateObject.toISOString().slice(0, 10);
    }
  }
  return date;
}

function cycleToTimelineArray(cycle) {
  if (cycle.service_channel === "agency") {
    agency.forEach((item) => {
      const dateValue = cycle[item.event];
      item.date = normalizeDate(dateValue);
      item.done = dateValue !== null;
    });
    return agency;
  } else if (cycle.service_channel === "drop-ship") {
    dropShip.forEach((item) => {
      const dateValue = cycle[item.event];
      item.date = normalizeDate(dateValue);
      item.done = dateValue !== null;
    });
    return dropShip;
  }
  return [];
}

export default function ServiceTimeline({ cycle }) {
  const items = cycleToTimelineArray(cycle);
  const timelineItems = items.map((value, i, { length }) => {
    const connector = i + 1 < length ? <TimelineConnector /> : "";
    return (
      <TimelineItem key={value.event}>
        <TimelineOppositeContent color="textSecondary">
          <Typography variant="body2">{value.date}</Typography>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot
            color={value.compliant ? "success" : "warning"}
            variant={value.done ? "filled" : "outlined"}
          />
          {connector}
        </TimelineSeparator>
        <TimelineContent>
          <Typography variant="body2">{value.name}</Typography>
          {value.name === "Fitting" && cycle.pref_arrive_on ? (
            <Typography variant="caption">
              Schd: {cycle.pref_arrive_on}
            </Typography>
          ) : (
            ""
          )}
          {value.name === "Collected" && cycle.pref_pickup_on ? (
            <Typography variant="caption">
              Schd: {cycle.pref_pickup_on}
            </Typography>
          ) : (
            ""
          )}
        </TimelineContent>
      </TimelineItem>
    );
  });
  return <Timeline sx={{ px: 0 }}>{timelineItems}</Timeline>;
}
