import * as React from "react";
import BaseLayout from "../../layouts/BaseLayout";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faTruckPickup,
  faGift,
  faBedPulse,
} from "@fortawesome/pro-solid-svg-icons";
import ColoredCard from "../../components/ColoredCard";
import { Alert, CardHeader } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { dateFmt } from "../../formatters";
import Divider from "@mui/material/Divider";
import { useEffect, useState } from "react";

const item = {
  id: 1,
  serial: "A12345",
  status: "available",
  type: "serialized",
  name: "VascuTherm 4",
  history: [
    {
      patient_name: "John Smith",
      patient_id: 11,
      date_fit: "2022-08-20T22:32:45.460Z",
      date_collected: "2022-10-25T22:32:45.460Z",
    },
    {
      patient_name: "Jane Doe",
      patient_id: 14,
      date_fit: "2022-07-15T22:32:45.460Z",
      date_collected: "2022-08-17T22:32:45.460Z",
    },
    {
      patient_name: "Kade Robinson",
      patient_id: 18,
      date_fit: "2022-05-06T22:32:45.460Z",
      date_collected: "2022-07-08T22:32:45.460Z",
    },
  ],
};

const statuses = {
  available: (
    <Typography variant="h5">
      Status: <FontAwesomeIcon color="green" icon={faCircleCheck} /> Available
    </Typography>
  ),
  pickup: (
    <Typography variant="h5">
      Status: <FontAwesomeIcon color="red" icon={faTruckPickup} /> Needs to be
      Picked Up
    </Typography>
  ),
  probono: (
    <Typography variant="h5">
      Status: <FontAwesomeIcon color="orange" icon={faGift} /> Pro Bono
    </Typography>
  ),
  "with-patient": (
    <Typography variant="h5">
      Status: <FontAwesomeIcon color="lightskyblue" icon={faBedPulse} /> With
      Patient
    </Typography>
  ),
};

function InventoryItem() {
  const { id } = useParams();
  const { api } = useOutletContext();
  const navigate = useNavigate();
  const [inventoryData, setInventoryData] = useState(["init", {}]);
  const [status, data] = inventoryData;

  useEffect(() => {
    if (status === "init") {
      api
        .getInventoryId(id)
        .then((d) => {
          setInventoryData(["loaded", d]);
        })
        .catch((error) => {
          setInventoryData(["error", error]);
        });

      setInventoryData(["loading", []]);
    }
  }, [id, status, api]);

  if (status === "error") {
    return (
      <BaseLayout>
        <Alert severity="error">Unable to load requested inventory</Alert>
      </BaseLayout>
    );
  }

  if (status !== "loaded") return null;

  return (
    <BaseLayout>
      <Box sx={{ width: "100%" }}>
        <Typography variant="h3" align="center">
          {item.name} ({data.serial_num})
        </Typography>
        <Divider sx={{ my: 2 }} />
        {statuses[data.status]}
        <Divider sx={{ my: 2 }} />
        <Typography variant="h5" mb={2}>
          Patient History:{" "}
        </Typography>
        <Stack spacing={1}>
          {data.history.map((h) => {
            return (
              <ColoredCard status="info" key={h.eoc}>
                <CardHeader
                  sx={{ paddingBottom: 0 }}
                  title={<Typography variant="h5">{h.patient_name}</Typography>}
                  subheader={
                    <Typography variant="subtitle2">
                      {h.patient_city}, {h.patient_state}
                    </Typography>
                  }
                  action={
                    <Stack direction="row" spacing={1}>
                      <IconButton
                        size="small"
                        onClick={() => navigate(`/patients/${h.eoc}`)}
                      >
                        <ArrowForwardIosIcon />
                      </IconButton>
                    </Stack>
                  }
                />
                <Typography sx={{ paddingLeft: 2 }} variant="overline">
                  {h.agnc_to_eoc && dateFmt(h.agnc_to_eoc)}
                  {" - "}
                  {h.eoc_to_agnc && dateFmt(h.eoc_to_agnc)}
                </Typography>
              </ColoredCard>
            );
          })}
        </Stack>
      </Box>
    </BaseLayout>
  );
}

export default InventoryItem;
